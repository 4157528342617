import axios from 'axios';

import * as Ala from './ale_model.js';
import * as Auth from '../auth/auth.js';




/*--------------------- AGGIUNGO ALA ALLA STRUTTURA ---------------------*/
async function addAla(token, id, descrizione, id_piano) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovaAla = new Ala.AddAla(id, descrizione, id_piano);

        const response = await axios.post(`${Auth.default.url}/ale`, JSON.stringify(nuovaAla.toJson()), { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() }
        };
    }
}

/*--------------------- ELIMINO ALA DALLA STRUTTURA ---------------------*/
async function deleteAla(token, id_ala) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/ale/${id_ala}`, { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- AGGIUNGO CORRIDOIO ALL'ALA ---------------------*/
async function putCorridoioinAla(token, id_ala, id_corridoio) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.put(`${Auth.default.url}/ale/${id_ala}/corridoi/${id_corridoio}`, {}, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- TOLGO CORRIDOIO DALL'ALA ---------------------*/
async function deleteCorridoioinAla(token, id_ala, id_corridoio) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/ale/${id_ala}/corridoi/${id_corridoio}`, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- AGGIUNGO STANZA IN ALA ---------------------*/
async function putStanzainAla(token, id_ala, id_stanza) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.put(`${Auth.default.url}/ale/${id_ala}/stanze/${id_stanza}`, {}, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- ELIMINO STANZA DA ALA ---------------------*/
async function deleteStanzainAla(token, id_ala, id_stanza) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/ale/${id_ala}/stanze/${id_stanza}`, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function editDescrizione(token, id_ala, desc) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.patch(`${Auth.default.url}/ale/${id_ala}`, desc, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { addAla, deleteAla, putCorridoioinAla, putStanzainAla, deleteCorridoioinAla, deleteStanzainAla, editDescrizione };
