import React, { useState, useEffect } from "react";
import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import userPurple from '../imgs/icons/userPurple.svg';
import auth from "node/auth/auth.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_utente;

const NuovoUtente = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [mod, setMod] = useState(0);
    const [status, setStatus] = useState(0);
    const [nome, setNome] = useState();
    const [cognome, setCognome] = useState();
    const [tipo, setTipo] = useState(accountData.tipo === 0 ? 0 : accountData.tipo === 1 ? 3 : accountData.tipo === 3 ? 4 : null);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const tipoLetterale = tipo === 0 ? "Admin" : tipo === 1 ? "IT Interno" : tipo === 2 ? "Manut Esterno" : tipo === 3 ? "Coordinatore infermiere" : tipo === 4 ? "Personale di corsia" : "Personale di servizio";

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    async function creaUtente() { // crea un nuovo utente
        props.loading(true); // mostra il popup di caricamento
        await auth.newUser(accountData.token, nome, cognome, email, password, parseInt(tipo)).then((res) => { // richiede al server di creare un nuovo utente
            props.getUtenti(); // aggiorna la lista degli utenti
            props.closePopup(); // chiude il popup
        }).finally(() => { // alla fine nasconde il popup di caricamento
            props.loading(false);
        });
    }

    useEffect(() => { // resetta lo stato quando si chiude il popup
        setStatus(0); // 0 = crea, 1 = modifica
        setNome(""); 
        setCognome(""); // 0 = admin, 1 = IT, 2 = Manut, 3 = Coordinatore, 4 = Personale
        setTipo(""); 
        setEmail(""); //
        setPassword(""); 
    }, [props]); //

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto"}}>
                <div className={selSelettore}>
                    <p>{langSet.dettagli}</p>
                </div>
            </div>
            <div id="PopupBody">
                <form id="form" onSubmit={creaUtente}>
                    <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                        <tbody>
                            <tr>
                                <td width="15%" rowSpan={2} className="schedaImg">
                                    <img src={userPurple} alt="position" />
                                </td>
                                <td width="16.5%">
                                    <label>{langSet.nome}</label>
                                    <input
                                        type="text"
                                        placeholder={langSet.inserisciNome}
                                        onChange={(e) => setNome(e.target.value)}
                                        required
                                    />

                                </td>
                                <td width="16.5%">
                                    <div>
                                        <label>{langSet.cognome}</label>
                                        <input
                                            type="text"
                                            placeholder={langSet.inserisciCognome}
                                            onChange={(e) => setCognome(e.target.value)}
                                            required
                                        />
                                    </div>
                                </td>
                                <td width="16.5%">
                                    <div>
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </td>
                                <td width="35.5%">
                                </td>
                                
                            </tr>
                            <tr>
                                <td width="16.5%">
                                    <label>{langSet.tipoDiUtenza}</label>
                                    {accountData.tipo === 0 ?
                                        <select value={tipo} onChange={(e) => setTipo(e.target.value)}  style={{ padding: "0px", width: "100%"}} >
                                            <option value={0}>Admin</option>
                                            <option value={1}>{langSet.itInterno}</option>
                                            <option value={2}>{langSet.manutEsterno}</option>
                                            <option value={3}>{langSet.coordinatoreInfermiere}</option>
                                            <option value={4}>{langSet.personaleDiCorsia}</option>
                                        </select> : accountData.tipo === 1 ?
                                            <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                                <option value={3}>{langSet.coordinatoreInfermiere}</option>
                                            </select> : accountData.tipo === 3 ?
                                                <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                                    <option value={4}>{langSet.personaleDiCorsia}</option>
                                                </select> :
                                                <></>
                                    } {/*se l'utente è admin può creare tutti i tipi di utente, se è IT solo coordinatore infermiere, se è coordinatore infermiere solo personale di corsia*/}
                                </td>
                                <td width="16.5%">
                                    <label>{langSet.dataDiRegistrazione}</label>
                                    <p style={{ width: "10em", position: "relative", top: ".25em" }}>
                                        {new Date().getDate() +
                                            "/" +
                                            (new Date().getMonth() + 1) +
                                            "/" +
                                            new Date().getFullYear()} 
                                    </p>
                                </td>
                                <td width="16.5%">
                                    <div>
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                </td>
                                <td width="35.5%" align="right" valign="bottom">
                                    <div className={nome !== undefined && cognome !== undefined && email !== undefined && tipo !== undefined && password !== undefined ? defContinua : "disabled " + defContinua} // se tutti i campi sono compilati abilita il pulsante
                                        onClick={nome !== undefined && cognome !== undefined && email !== undefined && tipo !== undefined && password !== undefined ? () => creaUtente() : () => { }} // se tutti i campi sono compilati chiama la funzione creaUtente
                                    >
                                        <p>{langSet.salvaEContinua}</p>
                                        <img className="icon-popup" src={confirmW} alt='confirm' />
                                    </div>
                                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                        <p>{langSet.annulla}</p>
                                        <img className="icon-popup" src={close} alt="close" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    );
}

export default NuovoUtente;