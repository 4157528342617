// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {

            varco: 'Gap',
            ascensore: 'Escalator',
            bagno: 'Bathroom',
            stanza: 'Room',
            piano: 'Floor',
            caduta: 'Fall',
            presenza: 'Presence',
            offline: 'Offline',
            assente: 'Absent',
            presente: 'Present',
            occupato: 'Busy',
            vuoto: 'Empty',
            occupazione: 'Occupation',
            stato: 'State',
            nome: 'Name',
            cognome: 'Surname',
            data: 'Date',
            ora: 'Time',
            tipo: 'Type',
            id: 'ID',
            residente: 'Resident',
            residenti: 'Residents',
            dx : 'Right',
            sx : 'Left',
            centrale : 'Central',
            corridoio : 'Corridor',
            ingresso : 'Entrance',
            uscita : 'Exit',
            illettoèvuoto: 'The bed is empty',
            illettoèoccupato: 'The bed is occupied',
            ilbagnoèvuoto: 'The bathroom is empty',
            ilbagnoèoccupato: 'The bathroom is occupied',
            notifiche: 'Notifications',
            rilevateanomalienelpiano: 'Detected anomalies on the floor',
            nessunaallerta: 'No anomalies detected',
        },
      },
      es: {
        translation: {

        },
      },
        it: {
            translation: {
                varco: 'Varco',
                ascensore: 'Ascensore',
                bagno: 'Bagno',
                stanza: 'Stanza',
                piano: 'Piano',
                caduta: 'Caduta',
                presenza: 'Presenza',
                offline: 'Offline',
                assente: 'Assente',
                presente: 'Presente',
                occupato: 'Occupato',
                vuoto: 'Vuoto',
                occupazione: 'Occupazione',
                stato: 'Stato',
                nome: 'Nome',
                cognome: 'Cognome',
                data: 'Data',
                ora: 'Ora',
                tipo: 'Tipo',
                id: 'ID',
                residente: 'Residente',
                residenti: 'Residenti',
                dx : 'Destra',
                sx : 'Sinistra',
                centrale : 'Centrale',
                corridoio : 'Corridoio',
                ingresso : 'Ingresso',
                uscita : 'Uscita',
                illettoèvuoto: 'Il letto è vuoto',
                illettoèoccupato: 'Il letto è occupato',
                ilbagnoèvuoto: 'Il bagno è vuoto',
                ilbagnoèoccupato: 'Il bagno è occupato',
                notifiche: 'Notifiche',
                rilevateanomalienelpiano: 'Rilevate anomalie nel piano',
                nessunaallerta: 'Nessuna anomalia rilevata',


            },
        },
        de: {
            translation: {
                varco: 'Lücke',
                ascensore: 'Fahrstuhl',
                bagno: 'Bad',
                stanza: 'Zimmer',
                piano: 'Stock',
                caduta: 'Fall',
                presenza: 'Anwesenheit',
                offline: 'Offline',
                assente: 'Abwesend',
                presente: 'Anwesend',
                occupato: 'Beschäftigt',
                vuoto: 'Leer',
                occupazione: 'Besetzung',
                stato: 'Zustand',
                nome: 'Name',
                cognome: 'Nachname',
                data: 'Datum',
                ora: 'Zeit',
                tipo: 'Art',
                id: 'ID',
                residente: 'Bewohner',
                residenti: 'Bewohner',
                dx : 'Rechts',
                sx : 'Links',
                centrale : 'Zentral',
                corridoio : 'Korridor',
                ingresso : 'Eingang',
                uscita : 'Ausgang',
                illettoèvuoto: 'Das Bett ist leer',
                illettoèoccupato: 'Das Bett ist besetzt',
                ilbagnoèvuoto: 'Das Bad ist leer',
                ilbagnoèoccupato: 'Das Bad ist besetzt',
                notifiche: 'Benachrichtigungen',
                rilevateanomalienelpiano: 'Anomalien auf der Etage erkannt',
                nessunaallerta: 'Keine Anomalien erkannt',
            },
        },

      // Aggiungi altre lingue secondo necessità
    },
    lng: 'en', // Lingua predefinita
    fallbackLng: 'en', // Lingua di fallback
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
