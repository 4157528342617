import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch";

const ZohoForm = () => {
    const [formData, setFormData] = useState({
        xnQsjsdp: 'edbsnaf7f40158c855c1ced648ea04551c711',
        xmIwtLD: 'edbsnfd47b72f48e8ddc7d8d4cf70f496a8e15a4c60f6b936f3cf31a3f19decbbe8c2',
        xJdfEaS: '',
        actionType: 'Q2FzZXM=',
        returnURL: 'https://dashboard.care4u.it/',
        'First Name': '',
        'Contact Name': '',
        Email: '',
        Phone: '',
        'Struttura Richiedente': '',
        'Tipologia problema': '-Nessuno-',
        'Bloccante per il servizio': false,
        Subject: '',
        Description: '',
    });

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? e.target.checked : value,
        }));
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log('Form submitted:', formData);
    //
    //     const formDataToSubmit = new FormData();
    //     Object.keys(formData).forEach(key => {
    //         formDataToSubmit.append(key, formData[key]);
    //     });
    //
    //     // try {
    //     //     const response = await fetch('https://desk.zoho.eu/support/WebToCase', {
    //     //         method: 'POST',
    //     //         mode: "cors",
    //     //         body: formDataToSubmit,
    //     //     }
    //
    // }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);

        try {
            const response = await fetch('http://localhost:3000/zohoform', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok && response.status === 200) {
                const text = await response.text();
                if (text === 'HTML content forwarded to Zoho successfully') {
                    console.log('Success:', text);
                    toast("tetststststtstststst");
                    // props.closePopup()
                } else {
                    console.log('Unexpected response:', text);
                }
            } else {
                console.log('Error:', response.statusText);
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const handleReset = () => {
        setFormData({
            'First Name': '',
            'Contact Name': '',
            Email: '',
            Phone: '',
            'Struttura Richiedente': '',
            'Tipologia problema': '-Nessuno-',
            'Bloccante per il servizio': false,
            Subject: '',
            Description: '',
        });
    };

    return (
        <div id="zohoSupportWebToCase" align="center" style={{width: "42em"}}>
            <form style={{width: "50%"}}
                  name="zsWebToCase_135511000000726285"
                  id="zsWebToCase_135511000000726285"
                  action="https://desk.zoho.eu/support/WebToCase"
                  method="POST"
                  onSubmit={handleSubmit}
                // enctype="multipart/form-data"
            >
                <input type="hidden" name="xnQsjsdp" value="edbsnaf7f40158c855c1ced648ea04551c711"/>
                <input type="hidden" name="xmIwtLD"
                       value="edbsnfd47b72f48e8ddc7d8d4cf70f496a8e15a4c60f6b936f3cf31a3f19decbbe8c2"/>
                <input type="hidden" name="xJdfEaS" value=""/>
                <input type="hidden" name="actionType" value="Q2FzZXM="/>
                <input type="hidden" id="property(module)" value="Cases"/>
                <input type="hidden" id="dependent_field_values_Cases"
                       value='&#x7b;"JSON_VALUES"&#x3a;&#x7b;&#x7d;,"JSON_SELECT_VALUES"&#x3a;&#x7b;&#x7d;,"JSON_MAP_DEP_LABELS"&#x3a;&#x5b;&#x5d;&#x7d;'/>
                <input type="hidden" name="returnURL" value="https&#x3a;&#x2f;&#x2f;www.care4u.it&#x2f;"/>

                {/*<table border="0" cellSpacing="0" className="zsFormClass">*/}
                {/*<tr>*/}
                {/*    <td nowrap className="zsFontClass" width="25%" align="left">*/}
                {/*        Nome&nbsp;&nbsp;*/}
                {/*    </td>*/}
                {/*    <td align="left" width="75%">*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            maxLength="120"*/}
                {/*            name="First Name"*/}
                {/*            value={formData['First Name']}*/}
                {/*            onChange={handleChange}*/}
                {/*            className="manfieldbdr"*/}
                {/*        />*/}
                {/*    </td>*/}
                {/*</tr>*/}

                <label className="formleble" htmlFor="fname">Nome</label>
                <input type="text" maxLength="40" name="First Name" placeholder="Nome" value={formData['First Name']}
                       onChange={handleChange}/>

                <label className="formleble">Cognome</label>
                <input type="text" maxLength="40" name="Contact Name" placeholder="Cognome"
                       value={formData['Contact Name']} onChange={handleChange}/>

                <label className="formleble">E-mail</label>
                <input type="text" maxLength="120" name="Email" placeholder="Email" value={formData['Email']}
                       onChange={handleChange}/>

                <label className="formleble">Telefono</label>
                <input type="number" maxLength="15" minLength="7" name="Phone" placeholder="numero di telefono" className="inputnumber"
                       value={formData['Phone']} onChange={handleChange}/>

                <label className="formleble">Struttura Richiedente</label>
                <input type="text" maxLength="120" name="Struttura Richiedente" placeholder="Nome Struttura Richiedente"
                       value={formData['Struttura Richiedente']} onChange={handleChange}/>

                <label className="formleble">Tipologia problema</label>
                <select name="Tipologia problema" value={formData['Tipologia problema']} style={{padding: "0px", transform: "unset"}}
                        onChange={handleChange}>
                    <option value="-Nessuno-">-Nessuno-</option>
                    <option value="Sensore Danneggiato">Sensore Danneggiato</option>
                    <option value="APP Disconnessa">APP Disconnessa</option>
                    <option value="Dashboard Disconnessa">Dashboard Disconnessa</option>
                    <option value="Nuova Configurazione">Nuova Configurazione</option>
                    <option value="Verifica Allerte">Verifica Allerte</option>
                    <option value="Varie">Varie</option>
                </select>

                <label >Bloccante per il servizio</label>
                <input type="checkbox" name="Bloccante per il servizio" checked={formData['Bloccante per il servizio']}
                       onChange={handleChange}/>
                {/*<Switch*/}
                {/*    onChange={handleChange}*/}
                {/*    name="Bloccante per il servizio"*/}
                {/*    checked={formData['Bloccante per il servizio']}*/}
                {/*/>*/}

                <label>Oggetto</label>
                <input type="text" maxLength="255" name="Subject" value={formData['Subject']}
                           onChange={handleChange}/>

                <label>Descrizione</label>
                <textarea name="Description" maxLength="3000" value={formData['Description']}
                              onChange={handleChange}></textarea>



                {/*<div className="form-fields form-zohoh">*/}
                {/*    <label>Nome</label>*/}
                {/*    <input type="text" maxLength="120" name="First Name" style={{width: "50%"}}*/}
                {/*           value={formData['First Name']} onChange={handleChange}/>*/}
                {/*</div>*/}

                {/*<div className="form-fields form-zohoh">*/}
                {/*    <label>Cognome</label>*/}
                {/*    <input type="text" maxLength="120" name="Contact Name" style={{width: "50%"}}*/}
                {/*           value={formData['Contact Name']} onChange={handleChange}/>*/}
                {/*</div>*/}

                {/*<div className="form-fields form-zohoh">*/}
                {/*    <label>E-mail</label>*/}
                {/*    <input type="text" maxLength="120" name="Email" style={{width: "50%"}} value={formData['Email']}*/}
                {/*           onChange={handleChange}/>*/}
                {/*</div>*/}

                {/*/!*<div className="form-fields form-zohoh">*!/*/}
                {/*<label>Telefono</label>*/}
                {/*<input type="text" maxLength="120" name="Phone" value={formData['Phone']} onChange={handleChange}/>*/}


                {/*<label>Struttura Richiedente</label>*/}
                {/*<input type="text" maxLength="120" name="Struttura Richiedente"*/}
                {/*       value={formData['Struttura Richiedente']} onChange={handleChange}/>*/}


                {/*<label>*/}
                {/*    Tipologia problema*/}
                {/*    <select name="Tipologia problema" value={formData['Tipologia problema']}*/}
                {/*            onChange={handleChange}>*/}
                {/*        <option value="-Nessuno-">-Nessuno-</option>*/}
                {/*        <option value="Sensore Danneggiato">Sensore Danneggiato</option>*/}
                {/*        <option value="APP Disconnessa">APP Disconnessa</option>*/}
                {/*        <option value="Dashboard Disconnessa">Dashboard Disconnessa</option>*/}
                {/*        <option value="Nuova Configurazione">Nuova Configurazione</option>*/}
                {/*        <option value="Verifica Allerte">Verifica Allerte</option>*/}
                {/*        <option value="Varie">Varie</option>*/}
                {/*    </select>*/}
                {/*</label>*/}

                {/*<label>*/}
                {/*    Bloccante per il servizio*/}
                {/*    <input type="checkbox" name="Bloccante per il servizio"*/}
                {/*           checked={formData['Bloccante per il servizio']} onChange={handleChange}/>*/}
                {/*</label>*/}

                {/*<label>*/}
                {/*    Oggetto*/}
                {/*    <input type="text" maxLength="255" name="Subject" value={formData['Subject']}*/}
                {/*           onChange={handleChange}/>*/}
                {/*</label>*/}

                {/*<label>*/}
                {/*    Descrizione*/}
                {/*    <textarea name="Description" maxLength="3000" value={formData['Description']}*/}
                {/*              onChange={handleChange}></textarea>*/}
                {/*</label>*/}
                {/*</table>*/}



                <input
                    type="submit"
                    id="zsSubmitButton_135511000000726285"
                    className="zsFontClass"
                    value="Invia"
                />
                {/*<input*/}
                {/*    type="button"*/}
                {/*    className="zsFontClass"*/}
                {/*    value="Reimposta"*/}
                {/*    onClick={handleReset}*/}
                {/*/>*/}
            </form>

            <ToastContainer/>
        </div>
    );
};

export default ZohoForm;
