import React, { useEffect, useState } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import positionPurple from '../imgs/icons/positionPurple.svg';
import sensoriNode from "node/sensori/sensori.js";
import magazzinoNode from "node/magazzino/magazzino.js";
import delet from '../imgs/icons/delete.svg';
import magazzinoGeneraleNode from 'node/magazzino-generale/magazzino_generale.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_sensore;

const NuovoSensore = (props) => {
    const struttura = localStorage.getItem("struttura");
    const [status, setStatus] = useState(0);
    const [nome, setNome] = useState("");
    const [id, setId] = useState("");
    const [tipo, setTipo] = useState(0);
    const [modello, setModello] = useState(0);
    const [modelli, setModelli] = useState([]);
    const [sensori, setSensori] = useState();
    const [sensoriSel, setSensoriSel] = useState([]);
    const accountData = JSON.parse(localStorage.getItem("account"));

    const tipi = [
        { value: 'Sensore letto', label: 'Letto' },
        { value: 'Sensore geofencing', label: 'Geofencing' },
        { value: 'Sensore caduta', label: 'Caduta' },
        { value: 'Sensore presenza stanza', label: 'Presenza Stanza' },
        { value: 'All', label: 'Tutti' },
    ];


    async function getModellidaTipo(tipo) {
        if (tipo === 'All') {
            setModelli(["Vayyar"]);
        } else {
        props.loading(true);
        let response = await sensoriNode.getModelli(accountData.token, tipo); //TODO: aggiungere il tipo
        if (!response.result) throw response.error; //TODO: gestire l'errore
        const resModelli = []; //TODO: aggiungere i modelli
        for (let sensore of response.data) {
            resModelli.push(sensore.model);
        }
        setModelli(resModelli);
        props.loading(false);
    }
    }

    async function addSensore() { 
        props.loading(true);

        if (struttura === 'Magazzino generale') {
            if (tipo === 'All') {
                for (let tipo of tipi) {
                    if (tipo.value !== 'All') {
                        console.log(tipo);
                        const fullname = nome + ' ' + tipo.label;
                        await magazzinoGeneraleNode.addSensoreGenerale(accountData.token, id, fullname, tipo.value, "Vayyar").then(() => {
                            props.getSens();
                        }).finally(() => {
                            props.loading(false);
                            props.closePopup();
                        });
                    }
                }
            } else {
            await magazzinoGeneraleNode.addSensoreGenerale(accountData.token, id, nome, tipo, modello).then(() => {
                props.getSens();
            }).finally(() => {
                props.loading(false);
                props.closePopup();
            });
            }
        } else {
            await magazzinoNode.addSensoreMagazzino(accountData.token, id, nome, tipo, modello).then(() => {
                props.getSens();
            }).finally(() => {
                props.loading(false);
                props.closePopup();
            });
        }
    }

    async function getMagazzinoGenerale() {
        props.loading(true);
        await magazzinoGeneraleNode.getMagazzinoGenerale(accountData.token).then((res) => {setSensori(res.data)});
        props.loading(false);
    }

    async function addSensoriDaMagazzinoGenerale() {
        props.loading(true);
        for(let id_sensore of sensoriSel) {
            await magazzinoGeneraleNode.patchSensoreMagazzinoGenerale(accountData.token, id_sensore);
        }
        props.loading(false);
        props.closePopup();
    }

    useEffect(() => {
        console.log(tipo);
        if(localStorage.getItem('struttura') === 'Magazzino generale') {
            setStatus(1);
        } else {
            getMagazzinoGenerale().then((res) => {setStatus(0);});
        }
    }, [props]);

    const defContinua = "selettore selettoreFlex selettoreBlue";
    return (
        <>
            {status === 1 ? <div id="Header" className="headerPopup">  {/* Header */}
                <div className="selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow"> {/* Selettore */}
                    <p>{langSet.dettagli}</p>   {/* Titolo */}
                </div>
            </div> : <></>} {/* Fine Header */}
            <div id="PopupBody">

                {status === 1 ?
                    <form id="form" className="middle_card">
                        <table className="scheda">
                            <tbody>
                                <tr>
                                    <td width="16.66%" rowSpan={2} className="schedaImg">
                                        <img src={positionPurple} alt="position" />
                                    </td>
                                    <td width="16.66%" >
                                        <div>
                                            <label htmlFor="nome">{langSet.nome}</label>
                                            <input
                                                type="text"
                                                placeholder={langSet.inserisciNome}
                                                onChange={(e) => setNome(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </td>
                                    <td width="16.66%">
                                        <div>
                                            <label htmlFor="id">ID</label>
                                            <input
                                                type="text"
                                                placeholder={langSet.inserisciId}
                                                onChange={(e) => setId(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="16.66%">
                                        <div>
                                            <label htmlFor="tipo">{langSet.tipoDiSensore}</label>
                                            <select defaultValue="0" onChange={(e) => {
                                                setTipo(e.target.value);
                                                getModellidaTipo(e.target.value);
                                            }} required> 
                                                <option value="0" disabled>- - -</option>
                                                {tipi.map((tipo, index) => (
                                                    <option key={index} value={tipo.value}>{tipo.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </td>
                                    <td width="16.66%">
                                        <div>
                                            <label htmlFor="tipo">{langSet.modelloSensore}</label>
                                            <select defaultValue="0" onChange={(e) => setModello(e.target.value)} required>
                                                <option value="0" disabled>- - -</option>
                                                {Array.isArray(modelli) && modelli.map((modello, index) => (
                                                    <option key={index} value={modello}>{modello}</option>
                                                ))}
                                            </select>

                                        </div>

                                    </td>
                                    <td width="50%" valign="bottom">
                                        <div className={tipo !== "" && nome !== '' && id !== "" && modello !== '' ? defContinua : "disabled " + defContinua} // se i campi sono vuoti disabilita il bottone
                                            onClick={tipo !== "" && nome !== '' && id !== "" && modello !== '' ? () => addSensore() : () => { }} // se i campi sono vuoti non fa niente
                                        >
                                            <p>{langSet.conferma}</p>
                                            <img src={confirmW} alt="confirm" /> 
                                        </div>
                                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}> {/* Bottone annulla */}
                                            <p>{langSet.annulla}</p>
                                            <img className="icon-popup" src={close} alt="close" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table></form> :
                    sensori === undefined ?
                        <option disabled>{langSet.erroreCaricamento}</option>
                        :
                        <>
                            <select defaultValue={0} value={0} style={{ margin: ".5em 1em", maxWidth: "25em", marginLeft: "1em", padding: "0 1em" }} onChange={(e) => setSensoriSel([...sensoriSel, e.target.value])}> {/* Selettore */}
                                <option value={0} disabled>{langSet.selezionaSensoreDaSpostareNelMagazzino}</option> 
                                {sensori.map((sensore, index) => {
                                    return (
                                        <option key={index} value={sensore.id}
                                            style={{ display: sensoriSel.includes(sensore.id) ? "none" : null }}>
                                            {sensore.nome} - {sensore.tipo}
                                        </option>
                                    );
                                })} {/* lista dei sensori da spostare */}
                            </select>

                            <div style={{ overflow: "scroll", maxHeight: "25em" }}>
                                <table id="TitoliLista">
                                    <tbody>
                                        <tr>
                                            <td width="15%" style={{ border: 0 }}>ID</td>
                                            <td width="15%" style={{ border: 0 }}>{langSet.nome}</td>
                                            <td width="15%" style={{ border: 0 }}>{langSet.tipo}</td>
                                            <td width="55%" style={{ border: 0 }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {sensoriSel.length > 0 ?
                                    sensoriSel.map((sensore, i) => (
                                        <table id="Lista" key={i}>
                                            <tbody>
                                                <tr key={sensore}>
                                                    <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori[sensori.findIndex((x) => x.id === sensore)].id}</td>
                                                    <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori[sensori.findIndex((x) => x.id === sensore)].nome}</td>
                                                    <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori[sensori.findIndex((x) => x.id === sensore)].tipo}</td>
                                                    <td width="55%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => { sensoriSel.splice(i, 1); setSensoriSel([...sensoriSel]) }}>
                                                            <p>{langSet.rimuovi}</p>
                                                            <img src={delet} alt="delete" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )) :
                                    <p style={{ textAlign: "center", margin: "1em", display: "" }}>{langSet.nessunSensoreAggiunto}</p>
                                } {/* lista dei sensori selezionati */}
                            </div>
                            <div className="navigators">
                                <div className={defContinua} onClick={() => addSensoriDaMagazzinoGenerale()}> {/* Bottone conferma */}
                                    <p>{langSet.salva}</p> 
                                    <img className="icon-popup" src={confirmW} alt='arrow' />
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}> {/* Bottone annulla */}
                                    <p>{langSet.annulla}</p>
                                    <img className="icon-popup" src={close} alt="close" />
                                </div>
                            </div>
                        </>
                } {}
            </div>
        </>
    );
}

export default NuovoSensore;