import React, { useState, useEffect } from "react";
import SoundPermissionPopup from "../components/SoundPermissionPopup";
import logo from "../imgs/logoGray.png";
import background from "../imgs/background.png";
import dashboard from "../imgs/icons/dashboard.svg";
import residenti from "../imgs/icons/residenti.svg";
import struttura from "../imgs/icons/struttura.svg";
import notifiche from "../imgs/icons/notifiche.svg";
import sensori from "../imgs/icons/sensori.svg";
import utenti from "../imgs/icons/utenti.svg";
import dashboardW from "../imgs/icons/dashboardW.svg";
import residentiW from "../imgs/icons/residentiW.svg";
import strutturaW from "../imgs/icons/strutturaW.svg";
import notificheW from "../imgs/icons/notificheW.svg";
import sensoriW from "../imgs/icons/sensoriW.svg";
import utentiW from "../imgs/icons/utentiW.svg";
import info from "../imgs/icons/gear.svg";
import infoW from "../imgs/icons/gearW.svg";
import linkw from "../imgs/icons/linkw.svg";
import wand from "../imgs/wand.cur";

import Menu from "../components/Menu.js";
import Header from "../components/Header.js";

import Dashboard from "../pages/dashboard/index.js";
import Residenti from "../pages/residenti/index.js";
import Struttura from "../pages/struttura/index.js";
import Notifiche from "../pages/notifiche/index.js";
import Sensori from "../pages/sensori/index.js";
import Utenti from "../pages/utenti/index.js";
import Info from "../pages/info/index.js";
import {call} from "assert";

// get nome struttura attuale
let strutturacorrente = "";
console.log(strutturacorrente);

function impostaStruttura(){
  strutturacorrente = localStorage.getItem("struttura");
  console.log(strutturacorrente);
}


function loading(bool) {
  bool ?
    document.getElementById("loading").style.display = "block"
    :
    document.getElementById("loading").style.display = "none";
}

function Home() {
  const [showPopup, setShowPopup] = useState(false);
  const [page, setPage] = useState(null);
  const [title, setTitle] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [keysPressed, setKeysPressed] = useState("");
  const accountData = JSON.parse(localStorage.getItem("account"));

  useEffect(() => {
    function handleKeyDown(event) {
      const { key } = event;
      setKeysPressed((prev) => prev + key);
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [keysPressed]);

  function refreshSensori() {
    setPage(<Sensori loading={loading} sortBy='nome' orderBy='asc' />); //? sortBy e orderBy sono i valori di default
  }

  function refreshStruttura() {
    setPage(<Struttura loading={loading} />); 
  }

  function refreshUtenti() { //
    setPage(<Utenti loading={loading} sortBy='nome' orderBy='asc' />); //
  }

  function refreshNotifiche() { 
    setPage(<Notifiche loading={loading} sortBy='creato' orderBy='asc' />);
  }

  function refreshResidenti() {
    setPage(<Residenti loading={loading} sortBy='cognome' orderBy='asc'/>);
  }

  function refreshDashboard() {
    setPage(<Dashboard filtro='griglia' Naviga={Naviga} loading={loading} />);
  }
  function refreshInfo() {
    setPage(<Info loading={loading} sortBy='nome' orderBy='asc' />);
  }

  useEffect(() => {
    impostaStruttura();
    creaItems();
    let login = localStorage.getItem("login");
    if (login === "true-no-remember") {
      localStorage.setItem("login", false);
    }
    let lang = localStorage.getItem("lang");
    if (lang === null) {
      localStorage.setItem("lang", "it");
    }
  }, []);

  function cerca(value) { //! questa ricerca funziona solo nelle liste, non nelle pagine
    let page = document.getElementById("ContentPage");
    let rows = page.getElementsByTagName("table");
    for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        let td = row.getElementsByTagName("td")[0];
        if (td) {
            let txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(value.toUpperCase()) > -1) {
                row.style.display = "";
            } else {
                row.style.display = "none";
            }
        }
    }
}

  function Naviga(title, filtro) { //? filtro è il parametro che serve per la dashboard
    let xpage = null;
    switch (title) {
      case "Dashboard":
        if (filtro === undefined) filtro = "griglia";
        xpage = <Dashboard filtro={filtro} Naviga={Naviga} loading={loading} cerca={cerca}/>;
        break;
      case "Residenti":
        xpage = <Residenti loading={loading} cerca={cerca}/>;
        break;
      case "Struttura":
        xpage = <Struttura loading={loading} cerca={cerca}/>;
        break;
      case "Notifiche":
        xpage = <Notifiche loading={loading} sortBy='creato' orderBy='asc' cerca={cerca}/>;
        break;
      case "Sensori":
        xpage = <Sensori loading={loading} sortBy='nome' orderBy='asc' cerca={cerca}/>;
        break;
      case "Utenti":
        xpage = <Utenti loading={loading} sortBy='nome' orderBy='asc' cerca={cerca}/>;
        break;
      case "Info":
        xpage = <Info loading={loading} sortBy='nome' orderBy='asc' cerca={cerca}/>;
        break;
      default:
        break;
    }
    setTitle(title);
    setShouldAnimate(true);
    setPage(xpage);
  }

  useEffect(() => {

    const timeoutId = setTimeout(() => {
      setShouldAnimate(false);
    }, 400);
    return () => clearTimeout(timeoutId);
  }, [page]);

  useEffect(() => {
    if (title === '') Naviga('Dashboard');
  }, [title]);

  const [items, setItems] = useState([]);
  function creaItems(){
      if(strutturacorrente === "SRV2210000002" || "SRV2210000001"){
        setItems([
          ["Dashboard", [dashboard, dashboardW]],
          ["Residenti", [residenti, residentiW]],
          ["Struttura", [struttura, strutturaW]],
          ["Notifiche", [notifiche, notificheW]],
          ["Sensori", [sensori, sensoriW]],
          ["Utenti", [utenti, utentiW]],
          ["Info", [info,infoW ]],
        ]);
      }else{
        setItems([
          ["Dashboard", [dashboard, dashboardW]],
          ["Residenti", [residenti, residentiW]],
          ["Struttura", [struttura, strutturaW]],
          ["Notifiche", [notifiche, notificheW]],
          ["Sensori", [sensori, sensoriW]],
          ["Utenti", [utenti, utentiW]]
        ]);
      }
      if (accountData.tipo === 4) {
        setItems([
          ["Dashboard", [dashboard, dashboardW]],
          ["Notifiche", [notifiche, notificheW]],
        ]);
      }
      if (accountData.tipo === 3) {
        setItems([
          ["Dashboard", [dashboard, dashboardW]],
          ["Notifiche", [notifiche, notificheW]],
          ["Residenti", [residenti, residentiW]],
        ]);
      }
      if (accountData.tipo === 2) {
        setItems([
          ["Info", [info,infoW ]],
        ]);
      }
  }

  useEffect(() => {
    creaItems();
    console.log(accountData.tipo);  
  }, [strutturacorrente]);

  const bod = (
    <div id="Base">

      <div id="Content" style={{ top: "0", left: "0", height: "100%" }}>
        <div id="Header" className="main-header" >
          <Header impostaStruttura={impostaStruttura} title={title} refreshDashboard={refreshDashboard} refreshSensori={refreshSensori} refreshStruttura={refreshStruttura} refreshUtenti={refreshUtenti} refreshNotifiche={refreshNotifiche} refreshResidenti={refreshResidenti} refreshInfo={refreshInfo} Naviga={Naviga} />
        </div>
        <div id="Menu">
          <div className="menuContent">
            {accountData.tipo !== 4 ? <Menu
              Naviga={Naviga}
              sel={title}
              items= {items}
            /> :
            <Menu
              Naviga={Naviga}
              sel={title}
              items={items}
            />
            }
          </div>
        </div>
        <div id="Body" className={shouldAnimate ? "fade-in" : ""}
             // style={{ width: "calc(96%)", marginLeft: "auto", marginRight: "auto", display: "inline-block" }}
        >
          <React.Fragment>
            {page}
          </React.Fragment>
        </div>
      </div>
    </div>
  );
  return bod;
}

export default Home;