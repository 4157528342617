

class AddAla {
    constructor(id, descrizione, id_piano){
        this.id = id;
        this.descrizione = descrizione;
        this.id_piano = id_piano;
    }
    toJson(){
        return {
            id: this.id,
            descrizione: this.descrizione,
            id_piano: this.id_piano
        }
    };
}


export { AddAla };