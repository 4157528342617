import axios from 'axios';
import { Piano, Stanza, GetStrutturaInfo } from './struttura_model.js';
import * as Auth from '../auth/auth.js';



/*--------------------- OTTENGO LA STRUTTURA ---------------------*/
async function getStrutturaAll(token) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.get(`${Auth.default.url}/struttura/all`, { headers });

        let struttura = response.data;

        let data = [];

        for (let piano of struttura) {
            data.push(new Piano(piano));
        }

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- OTTENGO LA LISTA DI STRUTTURE ---------------------*/
async function getListaStrutture(token) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.get(`${Auth.default.url}/struttura/lista`, { headers });

        let data = response.data;

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function getStrutturaNuovaStanza(token) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.get(`${Auth.default.url}/struttura/nuova_stanza`, { headers });

        let data = response.data;

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function getStrutturaInfo(token) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.get(`${Auth.default.url}/struttura/info`, { headers });

        let data = new GetStrutturaInfo(response.data);

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function addStruttura(token, id, nome, indirizzo, credentials) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.post(`${Auth.default.url}/struttura`, JSON.stringify({ id: id, nome: nome, indirizzo: indirizzo, credentials: credentials }), { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function editStruttura(token, nome) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.patch(`${Auth.default.url}/struttura`, nome, { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function delStruttura(token) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.delete(`${Auth.default.url}/struttura`, { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data.toString() };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { getStrutturaAll, getListaStrutture, getStrutturaNuovaStanza, getStrutturaInfo, addStruttura, editStruttura, delStruttura };