import React, { useEffect, useState } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from "../imgs/icons/confirmW.svg";
import arrow from "../imgs/icons/arrowFull.svg";
import BackArrowtoFirst from '../imgs/icons/back-arrow-to-first.svg';

import NuovoResidente from "./NuovoResidente.js";
import NuovoSensore from "./NuovoSensore.js";
import SchedaUtente from "./SchedaUtente.js";
import ProgressBar from "./ProgressBar.js";
import SchedaStanza from './SchedaStanza.js';
import SchedaSensore from "./SchedaSensore.js";
import SchedaNotifica from "./SchedaNotifica.js";
import SchedaResidente from "./SchedaResidente.js";
import DashboardResidente from "./DashboardResidente.js";
import DashboardAmbiente from "./DashboardAmbiente.js";
import NuovoUtente from "./NuovoUtente.js";
import NuovaStanza from "./NuovaStanza.js";
import SchedaStruttura from "./SchedaStruttura.js";
import NuovaStruttura from "./NuovaStruttura.js";
import notifiche from "node/notifiche/notifiche";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import residentiNode from "node/residenti/residenti.js";
import sensoriNode from "node/sensori/sensori.js";
import corridoiNode from 'node/corridoi/corridoi.js';
import pianiNode from 'node/piani/piani.js';
import alaNode from 'node/ale/ale.js';
import authNode from "node/auth/auth.js";
import magazzinoGeneraleNode from 'node/magazzino-generale/magazzino_generale.js';
import arrowPurple from "../imgs/icons/arrowPurple.svg";

// import htmlContent from './zoho/test.html';


import lang from "../lang.json";
import ZohoForm from "./ZohoForm";
const langSet = lang[localStorage.getItem("lang")];
const langSetRD = lang[localStorage.getItem("lang")].dashboard_residente;
const langsetNP = lang[localStorage.getItem("lang")].notifiche;



const Popup = (props) => {
    let handleContent = null;
    let bodyContent = null;
    let footerContent = null;
    const accountData = JSON.parse(localStorage.getItem("account"));
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang")
    if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
    }
    const [status, setStatus] = useState(0);

    const [page, setPage] = useState(0);

    const [sort, setSort] = useState('creato');
    const [order, setOrder] = useState('desc');
    const [isChecked, setIsChecked] = React.useState([]);
    const [notificheFeedback , setNotificheFeedback] = React.useState(Array());

    const defSelettore = "selettore selettoreFlex selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettorePurple";

    // console.log(notifiche);

    async function Sort(sorting) { // sorting = nome colonna
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        props.loading(false);
    }

    async function risolvi(id_residente, notificheList) {
        try {
            // inizio caricamento
            console.log(notificheFeedback);
            props.loading(true);
            if (notificheFeedback.length > 0) {
                // console.log(notificheList);
                await notifiche.patchNotificheFeedback(accountData.token, notificheFeedback).then( async (response) => {
                    // console.log(response);
                    if (!notifiche.filter(notifica => notifica.id_sensore.includes("fall")).length > 0 && id_residente !== undefined) {
                        await notifiche.patchAllStatoNotifica(accountData.token, "vuoto", id_residente, undefined).then( async (response) => {
                            // console.log(response);
                        })
                    }
                }).finally(() => {
                    // fine caricamento
                    setNotificheFeedback([]);
                    setIsChecked([]);

                    props.loading(false);
                    props.closePopup()
                    
                }
                );
            } else {
                if (id_residente !== undefined) {
            
                    await notifiche.patchAllStatoNotifica(accountData.token, "vuoto", id_residente, undefined).then( async (response) => {
                        // console.log(response);
                    }).finally(() => {
                        // fine caricamento
                        props.loading(false);
                        props.closePopup()
                        
                    }
                    );
                } else {
                    if (notificheFeedback.length > 0) {
                        console.log(notificheList);
                        await notifiche.patchNotificheFeedback(accountData.token, notificheFeedback).then( async (response) => {
                            console.log(response);
                            if (!notifiche.filter(notifica => notifica.id_sensore.includes("fall")).length > 0) {
                                for (let notifica of notificheList) {
                                    if (notifica.confermata === false) {
                                        await notifiche.patchStatoNotifica(accountData.token, notifica.id).then( async (response) => {
                                            console.log(response);
                                        });
                                    }
                                }
                            }
                        }).finally(() => {
                            // fine caricamento
                            setNotificheFeedback([]);
                            setIsChecked([]);

                            props.loading(false);
                            props.closePopup()
                            
                        }
                        );
                    }
                    for (let notifica of notificheList) {
                        if (notifica.confermata === false) {
                            await notifiche.patchStatoNotifica(accountData.token, notifica.id).then( async (response) => {
                                console.log(response);
                            });
                        }
                    }
                    props.loading(false);
                    props.closePopup()
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    // console.log(props.info[0].data);


    // console.log('ciaociao ciao cioa');
    // console.log(props.getRes);

    switch (props.info[0].category) {
        case 'notifiche':
            //visualizza notifiche non confermate in popup
            const notifiche = props.info[0].data;

            console.log(notifiche);

            if (isChecked.length !== notifiche.filter(notifica => notifica.id_sensore.includes("fall")).length) {
                let array = []

                for (let notifica of notifiche) {
                    array[notifica.id] = undefined
                }
                if (notifiche.length === array.length) {
                    setIsChecked(array)
                }
                //setIsChecked(Array(notifiche.filter(notifica => notifica.id_sensore.includes("fall")).length).fill(undefined));
            }
            console.log(notifiche);
            // ordino le notifiche per data di creazione
            notifiche.sort((a, b) => {
                // a.creato e b.creato hanno questo formato 14/11/2023 06:11
                // ordino per data e ora
                let aData = a.creato.split(' ')[0].split('/');
                let bData = b.creato.split(' ')[0].split('/');
                let aOra = a.creato.split(' ')[1].split(':');
                let bOra = b.creato.split(' ')[1].split(':');
                let aDate = new Date(aData[2], aData[1], aData[0], aOra[0], aOra[1]);
                let bDate = new Date(bData[2], bData[1], bData[0], bOra[0], bOra[1]);
                if (order === 'desc') {
                    return bDate - aDate;
                } else {
                    return aDate - bDate;
                }

            });
            
            //console.log(notifiche);
            handleContent = (
                <>
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{t("notifiche")}</div>
                </>
            );





            bodyContent = (
                <div>
                <div id="Header" style={{ height: "1rem", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    {/*<div className='headerSX'>*/}
                    {/*    */}
                    {/*</div>*/}
                    {notifiche ?
                        <>
                            <div className='headerDX'>
                                <div className='selettore selettoreFlex selettoreViolet' style={{cursor: 'default'}}>
                                    {/*<img src={BackArrowtoFirst}*/}
                                    {/*     onClick={() => setPage(0)}/>*/}
                                    {/* hdhdhdhdh
                                    { console.log("tetetetetetetetetetetetetetetettetetetetetetetete") } */}

                                    {Math.ceil(notifiche.length / 10) > 1 && (

                                        <img src={BackArrowtoFirst}
                                             style={{ width: '1.2rem', margin: '4px' }}
                                             onClick={() => setPage(0)}/>
                                    )}
                                    <img
                                        src={arrowPurple}
                                        style={{ transform: "rotate(90deg)", width: '1.2rem', margin: '4px' }}
                                        onClick={() => page > 0 ? setPage(page - 1) : null}/>
                                    <div style={{
                                        fontWeight: 200,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: 'default'
                                    }}>{langSet.pagina}&nbsp;{page + 1} / <p
                                        onClick={() => setPage(Math.ceil(notifiche.length / 10) - 1)}>{Math.ceil(notifiche.length / 10)}</p>
                                    </div>
                                    <img
                                        src={arrowPurple}
                                        style={{ transform: "rotate(-90deg)", width: '1.2rem', margin: '4px' }}
                                        onClick={() => page < Math.ceil(notifiche.length / 10) - 1 ? setPage(page + 1) : null}/>

                                    {/*<img src={BackArrowtoFirst}*/}
                                    {/*     style={{transform: "rotate(180deg)"}}*/}
                                    {/*     onClick={() => setPage(Math.ceil(notifiche.length / 10) - 1)}/>*/}

                                    {Math.ceil(notifiche.length / 10) > 1 && (
                                        <img src={BackArrowtoFirst}
                                             style={{transform: "rotate(180deg)", width: '1.2rem', margin: '4px'}}
                                             onClick={() => setPage(Math.ceil(notifiche.length / 10) - 1)}/>
                                    )}

                                </div>
                            </div>
                        </> :
                        <>
                        </>



                    }
                </div>
                    <div id="table-scroll" className="table-scroll table-scroll2" style={{ height : "calc(100% - 4.5em)", marginTop: "1rem" }}>
                    <table id="TitoliLista" className="main-table" style={{ width: "100%"}}>
                        <thead style={{ borderTop: "1px solid #F5F5F5", borderBottom: "1px solid #CDDEFF",borderRadius: "10px"}}>
                            <tr style={{ color: "#4DA6DD"}} >
                                <th className={sort === 'messaggio' ? 'selected' : null} style={{ zIndex:100, borderTopLeftRadius: "20px", borderBottomLeftRadius : "20px" }}
                                    // onClick={() => Sort('messaggio')}
                                >
                                    {/*{langsetNP.messaggio} &nbsp; */}
                                    Tipologia
                                </th>
                                <th className={sort === 'residente' ? 'selected' : null} style={{ zIndex:100 }}
                                    // onClick={() => Sort('residente')}
                                >
                                    {/*{langSetRD.residente} &nbsp; */}
                                    Da
                                </th>
                                {/*<th>{langSetRD.stanza} </th>*/}
                                <th className={sort === 'creato' ? 'selected' : null} onClick={() => Sort('creato')}
                                    style={{ zIndex : "100" }}>{langSetRD.creatoIl} &nbsp; <img src={arrowPurple}
                                                                                                       alt='arrow'
                                                                                                       className='arrowTitoli'
                                                                                                       id='Arrowcreato'/>
                                </th>
                                {notifiche.some(notifica => notifica.id_sensore.includes("fall")) ?
                                    <>
                                        <th style={{background: "#4DA6DD"}}>Vera</th>
                                        <th style={{ borderTopRightRadius: "20px", borderBottomRightRadius : "20px"  }}>Falsa</th>
                                    </>
                                    :
                                    <>
                                        <th style={{background: "#4DA6DD"}}></th>
                                        <th style={{borderTopRightRadius: "20px", borderBottomRightRadius : "20px" ,}}></th>
                                    </>}
                            </tr>
                        </thead>

                        <tbody style={{ fontSize:"medium", color: "black" }} className="tablestraip"
                            // className={notifica.confermata ? "" : "allarme"}
                        >

                        {notifiche === undefined ?
                            <div id="noData">
                                <p>{langSetRD.erroreCaricamento}</p>
                            </div> : notifiche.slice(page * 10, (page + 1) * 10).map((notifica, index) => {

                                // Handle checkbox change
                                const handleCheckboxChangetrue = (index, id_notifica) => {

                                    // Check if the notification with the given id exists in notificheFeedback
                                    const notificaIndex = notificheFeedback.findIndex(notifica => notifica.id === id_notifica);

                                    const newCheckedState = isChecked
                                    newCheckedState[id_notifica] = true;
                                    setIsChecked(newCheckedState);
                                    console.log(newCheckedState)
                                    // If the notification exists, create a new object to avoid modifying the original object directly
                                    const updatedNotifica = {
                                        ...notificheFeedback[id_notifica],
                                        feedbacktovayyar: true,
                                        timestampFeedback: notifica.timestampFeedback
                                    };

                                    // Update the notificheFeedback array with the modified object
                                    setNotificheFeedback(prevState => {
                                            const updatedArray = [...prevState];
                                            updatedArray[id_notifica] = updatedNotifica;
                                            return updatedArray;
                                        }
                                    );

                                };

                                const handleCheckboxChangefalse = (index, id_notifica) => {

                                    // Check if the notification with the given id exists in notificheFeedback
                                    const notificaIndex = notificheFeedback.findIndex(notifica => notifica.id === id_notifica);

                                    const newCheckedState = isChecked

                                    newCheckedState[id_notifica] = false;
                                    setIsChecked(newCheckedState);
                                    console.log(newCheckedState)
                                    console.log(isChecked.length)
                                    // If the notification exists, create a new object to avoid modifying the original object directly
                                    const updatedNotifica = {
                                        ...notificheFeedback[id_notifica],
                                        feedbacktovayyar: false,
                                        timestampFeedback: notifica.timestampFeedback
                                    };

                                    // Update the notificheFeedback array with the modified object
                                    setNotificheFeedback(prevState => {
                                        const updatedArray = [...prevState];
                                        updatedArray[id_notifica] = updatedNotifica;
                                        return updatedArray;
                                    });
                                };

                                return (

                                    // <tbody className={notifica.confermata ? "" : "allarme"}>
                                    <tr id="Lista"  className={notifica.confermata ? "border-0 bold" : "allarme bold"} style={{ backgroundColor: '#fbd9d3'}} >
                                        <td className="border-0" style={{textAlign: "center"}}>
                                            {/*{notifica.messaggio}*/}
                                            {/*{ notifica.messaggio == 'Il paziente è caduto' ? 'Possibile caduta' : notifica.messaggio }*/}
                                            { notifica.messaggio == 'Il paziente è caduto' ? 'Possibile caduta' : notifica.messaggio == 'Il letto è vuoto' ? 'Uscita letto' : notifica.messaggio }
                                        </td>
                                        {/*<td className="border-0" style={{textAlign: "center"}} >{notifica.residente.replace('Stanza', 'Letto')}</td>*/}
                                        <td className="border-0" style={{textAlign: "center"}}>{notifica.id_stanza}</td>
                                        <td className="border-0" style={{textAlign: "center"}}>{notifica.creato}</td>
                                        {notifica.id_sensore.includes("fall") ?
                                            <>
                                                <td className="border-0" style={{textAlign: "center"}}>
                                                    <div>
                                                        <input name={index + "changetotrue"} style={{
                                                            cursor: "pointer",
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                               type="checkbox"
                                                               checked={isChecked[notifica.id]}
                                                               onClick={() => handleCheckboxChangetrue(index, notifica.id)}/>
                                                    </div>
                                                </td>
                                                <td className="border-0" style={{textAlign: "center"}}>
                                                    <input name={index + "changetofalse"} style={{
                                                        cursor: "pointer",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                           type="checkbox"
                                                           checked={isChecked[notifica.id] === false ? true : false}
                                                           onClick={() => handleCheckboxChangefalse(index, notifica.id)}/>
                                                </td>
                                            </> : <>
                                                <td className="border-0"></td>
                                                <td className="border-0"></td>
                                            </>
                                        }
                                    </tr>
                                    // </tbody>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
                    <div className="popupNavigator" style={{ paddingTop: "4%" }}>
                </div>
                <div className="popupButtons" style={{ marginTop: "1em" }}>
                    <div className="selettore selettoreBlue selettoreFlex" style={notifiche.length > 0 && Object.values(isChecked).length === notifiche.filter(notifica => notifica.id_sensore.includes("fall")).length ? {} : { display: "none" }} onClick={() => risolvi(notifiche[0].id_residente, notifiche)} >
                        <p>{langSetRD.salvaERisolvi}</p>
                        <img src={confirmW} alt="confirm" className="icon-popup" />
                    </div>
                    <div className="selettore selettoreViolet selettoreFlex" onClick={() => {props.closePopup();setNotificheFeedback([]);
                    setIsChecked([]); setPage(0)}}>
                        <p>{langSetRD.annulla}</p>
                        <img className="icon-popup" style={{ width:"0.8rem", marginLeft:"4px" }} src={close} alt="close" />
                    </div>
                </div>
                    </div>
                   
            );
           
            break;
        case 'residente':
            let sens;
            let sens_amb;
            if (props.info[0].data.sensori) {
                sens = props.info[0].data.sensori.filter(sensore => sensore.banner > 0).length;
            }
            if (props.info[0].data.sensori_ambiente) {
                if(props.info[0].data.sensori_ambiente.length > 1)
                    sens_amb = props.info[0].data.sensori_ambiente.filter(sensore => sensore.banner > 0).length;
                else
                    sens_amb = props.info[0].data.sensori_ambiente.banner;
            }

            console.log('ciaociao ciao cioa');
            console.log(props.getRes);

            handleContent = (
                props.info[0].data ?
                    <>
                        <div className={status === 0 ? selSelettore : defSelettore} style={{ marginLeft: "1em" }} id="residente" onClick={() => setStatus(0)}>
                            <p>{langSet.dashboard_residente.residente}</p>
                            <div className={sens > 0 ? "dot red medium" : "dot green medium"} style={{ right: 0 }} />
                        </div>
                        <div className={status === 1 ? selSelettore : defSelettore} id="stanza" onClick={() => setStatus(1)} >
                            <p>{langSet.dashboard_residente.stanza}</p>
                            <div className={sens_amb > 0 ? "dot red medium" : "dot green medium"} style={{ right: 0 }} />
                        </div>
                        <div className={status === 2 ? selSelettore : defSelettore + " disabled"} id="bagno">
                            <p>{langSet.dashboard_residente.bagno}</p>
                            <div className="dot medium" style={{ right: 0 }} />
                        </div>
                    </> : null
            );
            bodyContent = (
                props.info[0].data ?
                    <DashboardResidente info={props.info[0].data} status={status} SchedaInScheda={props.SchedaInScheda} getDash={props.getDash} loading={props.loading} closePopup={props.closePopup} />
                    : null
            );
            break;
        case 'ambiente':
            handleContent = (
                <>
                    <div className="selettore selettoreFlex selettorePurple" style={{ marginLeft: "1em" }} id="ambiente">
                        <p>{langSet.dashboard.ambiente}</p>
                        <div className="dot green medium" style={{ right: 0 }} />
                    </div>
                    <div className="selettore selettoreFlex selettoreBorder" id="bagno">
                        <p>Bagno</p>
                        <div className="dot red medium" style={{ right: 0 }} />
                    </div>
                </>
            );
            bodyContent = (
                props.info[0].data ?
                    <DashboardAmbiente info={props.info[0].data} status={status} SchedaInScheda={props.SchedaInScheda} getDash={props.getDash} loading={props.loading} closePopup={props.closePopup} />
                    : null
            );
            break;
        case 'scheda_residente':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_residente.schedaResidente} </div>
                </>
            );
            bodyContent = (
                <SchedaResidente closePopup={props.closePopup} loading={props.loading} getRes={props.getRes} residente={props.info[0].info} SchedaInScheda={props.SchedaInScheda} />
            );
            break;
        case 'nuovo_residente':
            handleContent = (

                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_residente.nuovoResidente}</div>
            );
            bodyContent = (
                <NuovoResidente closePopup={props.closePopup} loading={props.loading} getRes={props.getRes} SchedaInScheda={props.SchedaInScheda} />
            );
            break;
        case 'nuovo_sensore':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_sensore.nuovoSensore}</div>
            );
            bodyContent = (
                <>
                    <NuovoSensore closePopup={props.closePopup} loading={props.loading} getSens={props.getSens} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'nuovo_utente':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_utente.nuovoUtente}</div>
            );
            bodyContent = (
                <>
                    <NuovoUtente closePopup={props.closePopup} loading={props.loading} getUtenti={props.getUtenti} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'nuova_stanza':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_stanza.nuovaStanza}</div>
            );
            bodyContent = (
                <>
                    <NuovaStanza closePopup={props.closePopup} loading={props.loading} getStr={props.getStr} percorso={props.info[0].info} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;

        case 'scheda_utente':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_utente.schedaUtente}</div>
            );
            bodyContent = (
                <>
                    <SchedaUtente closePopup={props.closePopup} loading={props.loading} utente={props.info[0].info} getUtenti={props.getUtenti} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'scheda_sensore':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_sensore.schedaSensore}</div>
                </>
            );
            bodyContent = (
                <>
                    <SchedaSensore closePopup={props.closePopup} loading={props.loading} sensore={props.info[0].info} getSens={props.getSens} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'scheda_notifica':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_notifica.schedaNotifica}</div>
            );
            bodyContent = (
                <>
                    <SchedaNotifica closePopup={props.closePopup} loading={props.loading} notifica={props.info[0].info} getNot={props.getNot} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'scheda_stanza':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_stanza.schedaStanza}</div>
                </>

            );
            bodyContent = (
                <>
                    <SchedaStanza closePopup={props.closePopup} loading={props.loading} stanza={props.info[0].info} getStr={props.getStr} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'nuova_struttura':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_struttura.nuovaStruttra}</div>
            );
            bodyContent = (
                <>
                    <NuovaStruttura closePopup={props.closePopup} loading={props.loading} getStr={props.getStr} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'scheda_struttura':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_struttura.schedaStruttura}</div>
            );
            bodyContent = (
                <>
                    <SchedaStruttura closePopup={props.closePopup} loading={props.loading} refresh={props.getStr} getStrInfo={props.getStrInfo} struttura={props.info[0].info} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;

        case 'ticket':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>Nuova richiesta di supporto</div>
            );
            bodyContent = (
                <>
                    <ZohoForm closePopup={props.closePopup} loading={props.loading} getStr={props.getStr} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;

        case 'alarm':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_notifica" ?
                        <img src={arrow} alt="arrow" className="popupNavigatorArrow"
                             onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)}/> : null}
                    {props.info[0].operation === "ritorna_utente" ?
                        <img src={arrow} alt="arrow" className="popupNavigatorArrow"
                             onClick={() => props.SchedaInScheda("scheda_utente", props.info[0].data)}/> : null}
                    {props.info[0].operation === "ritorna_struttura" ?
                        <img src={arrow} alt="arrow" className="popupNavigatorArrow"
                             onClick={() => props.SchedaInScheda("scheda_struttura", props.info[0].data)}/> : null}
                    {props.info[0].operation === "ritorna_stanza" ?
                        <img src={arrow} alt="arrow" className="popupNavigatorArrow"
                             onClick={() => props.SchedaInScheda("scheda_stanza", props.info[0].data)}/> : null}
                    {props.info[0].operation === "ritorna_sensore" ?
                        <img src={arrow} alt="arrow" className="popupNavigatorArrow"
                             onClick={() => props.SchedaInScheda("scheda_sensore", props.info[0].data)}/> : null}
                    <div style={{fontSize: "200%", marginLeft: ".5em"}}>{langSet.alarms.avviso}</div>
                </>
            );
            bodyContent = (
                <>
                    <div id="PopupBody">
                        {props.info[0].info}
                    </div>
                </>
            );
            if (props.info[0].operation === "logout") {
                footerContent = (
                    <>
                        <div className="selettore selettoreFlex selettoreBlue" onClick={() => props.Logout()}>
                            <p>{langSet.alarms.esciOra}</p>
                            <img className="icon-popup" src={confirmW} alt="confirm"/>
                        </div>
                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                            <p>{langSet.alarms.annulla}</p>
                            <img className="icon-popup" src={close} alt="close"/>
                        </div>
                        <br/>
                        <ProgressBar Logout={props.Logout}/>
                    </>
                );
            }
            if (props.info[0].operation === "deleteResidente") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close"/>
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue"
                                 onClick={() => residentiNode.archiviaResidente(accountData.token, props.info[0].data.id).then(() => props.getRes().finally(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm"/>
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteUtente") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close"/>
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue"
                                 onClick={() => authNode.deleteUser(accountData.token, props.info[0].data.id).then(() => props.getUtenti().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm"/>
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteSensore") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close"/>
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue"
                                 onClick={() => sensoriNode.patchSensoreMagazzino(accountData.token, props.info[0].data.id, false).then(() => props.getSens().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm"/>
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteSensoreMagazzinoGen") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close"/>
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue"
                                 onClick={() => magazzinoGeneraleNode.deleteSensoreMagazzinoGenerale(accountData.token, props.info[0].data.id).then(() => props.getSens().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm"/>
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deletePiano") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close"/>
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue"
                                 onClick={() => pianiNode.deletePiano(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm"/>
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteAla") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => alaNode.deleteAla(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteCorridoio") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img className="icon-popup" src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => corridoiNode.deleteCorridoio(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img className="icon-popup" src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            break;
        default:
            handleContent = (<div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.alarms.errore}</div>);
            bodyContent = (<div>{langSet.alarms.errore}</div>);
            break;
    }

    return (
        <>
            <div id="handle">
                {props.info[0].operation !== "logout" ? <img src={close} alt="close" className="closePopup"
                                                             // onTouchStart={() => props.closePopup()} onClick={() => props.closePopup()}
                                                             onTouchStart={() => {
                                                                 props.closePopup();
                                                                 setPage(0);
                                                             }}
                                                             onClick={() => {
                                                                 props.closePopup();
                                                                 setPage(0);
                                                             }}
                /> : null}
                {handleContent}
            </div>
            <div id="PopupBody"
                 // style={{ width: props.info[0].category !== "alarm" ? "85em" : "30em" }}
            >
                {bodyContent}
                {footerContent}
            </div>
        </>
    );
};

export default Popup;