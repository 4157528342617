import axios from 'axios';
import * as Crypto from '../crypt/crypt.js';
import * as Sensori from './sensori_model.js';
import * as Auth from '../auth/auth.js';



/*--------------------- OTTENGO LISTA TUTTI SENSORI ---------------------*/
async function getSensori(token, sort, orderby) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/sensori/all`, { params: { sortby: sort, orderby: orderby }, headers });

    let sensori = response.data;

    let data = [];

    for (let sensore of sensori) {
      data.push(new Sensori.GetSensoriAll(sensore));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- OTTENGO I DATI DEL SINGOLO SENSORE ---------------------*/
async function getSensore(token, id) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/sensori/${id}`, { headers });

    let data = new Sensori.GetSensoriAll(response.data);

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getModelli(token, tipo) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/sensori/modelli/${tipo}`, { headers });

    let modelli = response.data;

    return { result: true, data: modelli };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- MODIFICO IL NOME DEL SENSORE ---------------------*/
async function patchNomeSensore(token, id_sensore, nome) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const nuovoNome = new Sensori.PatchNomeSensore(nome);

    const response = await axios.patch(`${Auth.default.url}/sensori/${id_sensore}`,JSON.stringify(nuovoNome.toJson()), { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- SWITCH NOTOFICA SENSORE ---------------------*/
async function patchToogleNotifica(token, id_sensore, value) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.patch(`${Auth.default.url}/allarmi/${id_sensore}/${value}`, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- SPOSTO SENSORE NEL MAGAZZINO ---------------------*/
async function patchSensoreMagazzino(token, id_sensore, quarantena) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    let data = JSON.stringify({quarantena: quarantena});

    const response = await axios.patch(`${Auth.default.url}/sensori/${id_sensore}/magazzino`, data, { headers: headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data};
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { getSensori, getSensore, getModelli, patchNomeSensore, patchToogleNotifica, patchSensoreMagazzino };
