import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import addW from '../../imgs/icons/addW.svg';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';
import delet from '../../imgs/icons/delete.svg';

import PopupComp from '../../components/Popup.js';
import sensoriNode from 'node/sensori/sensori.js';
import stanzeNode from 'node/stanze/stanze.js';
import magazzinoGeneraleNode from 'node/magazzino-generale/magazzino_generale.js';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].sensori;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const struttura = localStorage.getItem('struttura');
    const [sort, setSort] = useState('nome');
    const [order, setOrder] = useState('asc');
    const [sensori, setSensori] = useState([]);
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [sensoriStanza, setSensoriStanza] = useState([]);

    async function Sort(sorting) {
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        await getSens(sortBy, orderBy);
    }
    async function getSens(sorting, ordeBy) { // get per i sensori
        props.loading(true);
        if (struttura === 'Magazzino generale') {
            await magazzinoGeneraleNode.getMagazzinoGenerale(accountData.token, sorting, ordeBy).then((response) => {
                if(!response.result) throw response.error;
                setSensori(response.data);
            }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
                props.loading(false);
            });
        } else {
            await sensoriNode.getSensori(accountData.token, sorting, ordeBy).then((response) => {
                if(!response.result) throw response.error;
                setSensori(response.data);
                console.log(response.data);
            }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
                props.loading(false);
            });
        }
    }

    useEffect(() => { // get per i sensori ALL'APERTURA DELLA PAGINA
        props.loading(true);
        setTimeout(() => {
            getSens();
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);


    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) { // apre il popup
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) { 
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading"/>
            <div id="Popup">
                {/*<div onClick={() => centraPopup()} id="options">*/}
                {/*    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}*/}
                {/*</div>*/}
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getSens={getSens} closePopup={closePopup} loading={props.loading}
                                   SchedaInScheda={SchedaInScheda}/>
                    </div>
                </Draggable>
            </div>
            <div id="Header">
                <div className='headerSX'>
                    <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)}
                           placeholder={langSet.cerca}/>
                </div>
                <div className='headerDX'>
                    <div style={{display: accountData.tipo === 4 ? "none" : {width: "12.5em"}}}
                         className='selettore selettoreFlex selettorePurple'
                         onClick={() => openPopup('nuovo_sensore', '', 'addSensore', '')}>
                        <p style={{ marginRight: "0.5rem" }} >{langSet.aggiungiSensore}</p>
                        <img src={addW} alt='add'/>
                    </div>
                </div>
            </div>


            <div id="table-scroll" className="table-scroll table-scroll2">
                <table id="TitoliLista" className="main-table" style={{ width: "100%"}} >
                    <thead style={{ borderTop: "1px solid #F5F5F5", borderBottom: "1px solid #CDDEFF",borderRadius: "10px"}} >
                        <tr style={{ color: "#4DA6DD" }}>
                            <th style={{ zIndex:100, borderTopLeftRadius: "20px", borderBottomLeftRadius : "20px" }} className={sort === 'stato' ? 'selected' : null}
                                onClick={() => Sort('stato')}>{langSet.stato} &nbsp; <img src={arrowPurple} alt='arrow'
                                className='arrowTitoli' id='Arrowstato'/>
                            </th>

                            <th style={{ zIndex:100 }}>{langSet.posizione}</th>

                            <th style={{ zIndex:100 }} className={sort === 'tipo' ? 'selected' : null}
                                onClick={() => Sort('tipo')}>{langSet.tipo} &nbsp; <img src={arrowPurple} alt='arrow'
                                                                                        className='arrowTitoli'
                                                                                        id='Arrowtipo'/></th>

                            <th style={{ zIndex:100 }}>ID</th>


                            {/*<td width="7%" className={sort === 'batteria' ? 'selected' : null}*/}
                            {/*    onClick={() => Sort('batteria')}>{langSet.batteria} &nbsp; <img src={arrowPurple}*/}
                            {/*                                                                    alt='arrow'*/}
                            {/*                                                                    className='arrowTitoli'*/}
                            {/*                                                                    id='Arrowbatteria'/></td>*/}
                            <th style={{ borderTopRightRadius: "20px", borderBottomRightRadius : "20px" ,zIndex : "100"}}></th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize:"medium", color: "black" }} className="tablestraip" >

                        {sensori === undefined ?
                            <div id="noData">
                                <p>{langSet.erroreCaricamento}</p>
                            </div>
                            : sensori.map((sensore, index) => {
                                return (
                                    <tr id="Lista" key={sensore.id || index} className="backgraund-trasparent">

                                        <td style={{textAlign: "center"}}>{sensore.stato === "Online" ?
                                            <span className="online">●</span> : <span className="offline">●</span>}</td>

                                        <td style={{fontSize: "90%", textAlign: "center"}}>{sensore.percorso}</td>

                                        <td style={{textAlign: "center"}} >{sensore.tipo}</td>

                                        <td style={{textAlign: "center"}} >{sensore.id}</td>

                                        {/*<td width="7%">{sensore.batteria}</td>*/}

                                        <td>
                                            <div style={{display: accountData.tipo === 4 ? "none" : ""}}
                                                 className='selettore selettoreFlex selettoreBorder'
                                                 onClick={() => (struttura !== 'Magazzino generale' ? openPopup("alarm", langAlarms.ilSensore + sensore.nome + " - " + sensore.id + langAlarms.verraSpostato, "deleteSensore", sensore) : openPopup("alarm", langAlarms.ilSensore + sensore.nome + " - " + sensore.id + langAlarms.verraEliminato, "deleteSensoreMagazzinoGen", sensore))}>
                                                {/*<p>{langSet.elimina}</p>*/}
                                                <img src={delet} alt='delete'/>
                                            </div>
                                            <div
                                                style={{ padding: "4px 1.25em" }}
                                                className='selettore selettoreFlex selettoreBorder'
                                                 onClick={() => openPopup("scheda_sensore", sensore)}>
                                                {/*<p>{langSet.schedaSensore}</p>*/}
                                                <img src={arrowPurple} alt='arrow'
                                                     style={{transform: "rotate(-90deg)"}}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>

        </>
    );
}