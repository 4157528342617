

class GetNotificheModel {
    constructor(json) {
        this.messaggio = json.messaggio;
        this.id = json.id;
        this.tipo = json.tipo;
        this.id_sensore = json.id_sensore;
        this.residente = json.residente;
        this.id_stanza = json.id_stanza;
        this.creato = json.creato;
        this.timestamp = json.timestamp;
        this.confermata = json.confermata;
        this.lingua = json.lingua;
        this.nomeutente = json.nomeutente;
        this.note = json.note;
        this.id_residente = json.id_residente;
        this.timestampFeedback = json.timstampFeedback;
    }

}

class PutNoteNotifica {
    constructor(nota) {
        this.nota = nota;
    }
}
class PutNoteNotificaeresidente {
    constructor(nota, id_residente, id_stanza) {
        this.nota = nota;
        this.id_residente = id_residente;
        this.id_stanza = id_stanza;
    }
    toJson(){
        return {
            id_residente: this.id_residente,
            id_stanza: this.id_stanza,
            nota: this.nota
        }
    }
}


export { GetNotificheModel, PutNoteNotifica, PutNoteNotificaeresidente };
