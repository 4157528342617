import React, { useState, useEffect } from "react";
import arrowW from "../imgs/icons/arrowW.svg";
import arrowPurple from "../imgs/icons/arrowPurple.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import close from "../imgs/icons/close.svg";
import strutturaPurple from "../imgs/icons/strutturaPurple.svg";
import userPurple from '../imgs/icons/userPurple.svg';

import strutturaNode from 'node/struttura/struttura.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_struttura;

const NuovaStruttura = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [status, setStatus] = useState(0);
    const [id, setId] = useState();
    const [nomeStr, setNomeStr] = useState();
    const [indirizzo, setIndirizzo] = useState();
    const [nome, setNome] = useState();
    const [cognome, setCognome] = useState();
    const [tipo, setTipo] = useState(0);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    const defContinua = "selettore selettoreFlex selettoreBlue";
    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder noCursor";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow noCursor";

    // creazione della struttura
    async function aggiungiStruttura() {
        props.loading(true);
        await strutturaNode.addStruttura(accountData.token, id, nomeStr, indirizzo, { nome: nome, cognome: cognome, tipo: tipo, email: email, password: password }).then(() => {
            props.getStr();
        }).finally(() => {
            props.closePopup();
            props.loading(false);
        });
    }

    useEffect(() => {
        setStatus(0);
        setId("");
        setNomeStr("");
        setIndirizzo("");
        setNome("");
        setCognome("");
        setTipo(0);
        setEmail("");
        setPassword("");
    }, [props]);

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto" }}>
                <div className={status === 0 ? selSelettore : defSelettore}>
                    <p>{langSet.struttura}</p>
                </div>
                <div className={status === 1 ? selSelettore : "disabled " + defSelettore}>
                    <p>{langSet.utente}</p>
                </div>
            </div>
            <div id="PopupBody">
                {status === 0 ? <table className="scheda">
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={strutturaPurple} alt="struttura" />
                            </td>
                            <td width="17.5%">
                                <label>ID</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciId}
                                    value={id ?? ''}
                                    onChange={(e) => setId(e.target.value)}
                                    required
                                />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.nome}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciNome}
                                    value={nomeStr ?? ''}
                                    onChange={(e) => setNomeStr(e.target.value)}
                                    required
                                />
                            </td>
                            <td width="50%" rowSpan={2} valign="bottom">
                                <div className={id && nomeStr && indirizzo ? defContinua : "disabled " + defContinua}
                                    onClick={id && nomeStr && indirizzo ? () => setStatus(1) : () => { }}>
                                    <p>{langSet.continua}</p>
                                    <img src={arrowW} alt="arrow" style={{ transform: "rotate(-90deg)" }} />
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                    <p>{langSet.annulla}</p>
                                    <img className="icon-popup" src={close} alt="close" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <label>{langSet.indirizzo}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciIndirizzo}
                                    value={indirizzo ?? ''}
                                    onChange={(e) => setIndirizzo(e.target.value)}
                                    required
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                    :
                <table className="scheda">
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={userPurple} alt="position" />
                            </td>
                            <td width="16.5%">
                                <label>{langSet.nome}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciNome}
                                    value={nome ?? ''}
                                    onChange={(e) => setNome(e.target.value)}
                                    required
                                />

                            </td>
                            <td width="16.5%">
                                <div>
                                    <label>{langSet.cognome}</label>
                                    <input
                                        type="text"
                                        placeholder={langSet.inserisciCognome}
                                        value={cognome ?? ''}
                                        onChange={(e) => setCognome(e.target.value)}
                                        required
                                    />
                                </div>
                            </td>
                            <td width="16.5%">
                                <div>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        placeholder={langSet.inserisciEmail}
                                        value={email ?? ''}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </td>
                            <td width="35.5%">
                            </td>
                        </tr>
                        <tr>
                            <td width="16.5%">
                                <label>{langSet.tipoDiUtenza}</label>
                                <input
                                    type="text"
                                    placeholder="Admin"
                                    value="Admin"
                                    disabled
                                />
                            </td>
                            <td width="16.5%">
                                <label>{langSet.dataDiRegistrazione}</label>
                                <p style={{ width: "10em", position: "relative", top: ".25em" }}>
                                    {new Date().getDate() +
                                        "/" +
                                        (new Date().getMonth() + 1) +
                                        "/" +
                                        new Date().getFullYear()}
                                </p>
                            </td>
                            <td width="16.5%">
                                <div>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password ?? ''}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            </td>
                            <td width="35.5%" align="right" valign="bottom">
                                <div className={nome !== undefined && cognome !== undefined && email !== undefined && tipo !== undefined && password !== undefined ? defContinua : "disabled " + defContinua}
                                    onClick={nome !== undefined && cognome !== undefined && email !== undefined && tipo !== undefined && password !== undefined ? () => aggiungiStruttura() : () => { }}
                                >
                                    <p>{langSet.salvaEContinua}</p>
                                    <img className="icon-popup" src={confirmW} alt='confirm' />
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => setStatus(0)}>
                                    <p>{langSet.indietro}</p>
                                    <img src={arrowPurple} alt="arrow" style={{ transform: "rotate(90deg)" }} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                }

            </div>
        </>
    );
}

export default NuovaStruttura;