
/*--------------------- CLASSE TUTTI RESIDENTI ---------------------*/
class GetResidentiAll {
    constructor(json) {
        this.id = json.id;
        this.nome = json.nome;
        this.cognome = json.cognome;
        this.stanza = json.stanza;
        this.percorso = json.percorso;
        this.registrazione = json.registrazione;
    }
}


/*--------------------- CLASSE SCHEDA RESIDENTE ---------------------*/
class GetSchedaResidente {
    constructor(json) {
        this.id = json.id;
        this.nome = json.nome;
        this.cognome = json.cognome;
        this.registrazione = json.registrazione;
    }
}


/*--------------------- CLASSE MODIFICA RESIDENTE ---------------------*/
class PatchSchedaResidente{
    constructor(nome, cognome) {
        this.nome = nome;
        this.cognome = cognome;
    }
    toJson(){
        if(this.nome === ''){
            return {
                cognome: this.cognome
            }
        }
        else if(this.cognome === ''){
            return {
                nome: this.nome,
            }
        }
        else if (this.nome && this.cognome !== ''){
            return {
                nome: this.nome,
                cognome: this.cognome
            }
        }
    }
}


/*--------------------- CLASSE CAMBIO STANZA RESIDENTE ---------------------*/
class PatchStanzaResidente {
    constructor(id_stanza) {
        this.id_stanza = id_stanza;
    }
    toJson() {
        return {
            id_stanza: this.id_stanza
        }
    }
}


/*--------------------- CLASSE SENSORI RESIDENTE ---------------------*/
class GetSensoriResidente {
    constructor(json) {
        this.id_sensore = json.id;
        this.stato = json.stato;
        this.tipo = json.tipo;
        this.modello = json.modello;
        this.batteria = json.batteria;
        this.nome = json.nome;
    }
}


/*--------------------- CLASSE ALLARMI RESIDENTE ---------------------*/
class GetAllarmiResidente {
    constructor(json) {
        this.id_allarme = json.id;
        this.data = json.data;
        this.ora = json.ora;
        this.tipo = json.tipo;
        this.stato = json.stato;
        this.descrizione = json.descrizione;
        this.id_stanza = json.id_stanza;
        this.id_sensore = json.id_sensore;
        this.id_residente = json.id_residente;
    }
}


/*--------------------- CLASSE NUOVO RESIDENTE ---------------------*/
class AddResidente {
    constructor(id, nome, cognome, id_stanza, sensori) {
        this.id = id;
        this.nome = nome;
        this.cognome = cognome;
        this.id_stanza = id_stanza;
        this.sensori = sensori;
    }

    toJson() {
        return {
            id: this.id,
            nome: this.nome,
            cognome: this.cognome,
            id_stanza: this.id_stanza,
            sensori: this.sensori
        }
    }
}
export { GetResidentiAll, AddResidente, GetAllarmiResidente, GetSensoriResidente, GetSchedaResidente, PatchSchedaResidente, PatchStanzaResidente };
