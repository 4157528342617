class Piano {
    constructor(piano) {
        this.id = piano.id;
        this.tipo = piano.tipo;
        this.descrizione = piano.descrizione;
        this[piano.id] = [];
        if(piano[piano.id]){
            for(let ala of piano[piano.id]) {
                this[piano.id].push(new Ala(ala));
            }
        }
    }
}

class Ala {
    constructor(ala) {
        this.id = ala.id;
        this.tipo = ala.tipo;
        this.descrizione = ala.descrizione;
        this[ala.id] = [];
        if(ala[ala.id]){
            for(let generico of ala[ala.id]){
                if(generico.tipo === 'corridoio') {
                    this[ala.id].push(new Corridoio(generico));
                } else {
                    this[ala.id].push(new Stanza(generico))
                }
                
            }
        }
    }
}

class Corridoio {
    constructor(corridoio) {
        this.id_thinkin = corridoio.id_zona_thinkin;
        this.id = corridoio.id;
        this.tipo = corridoio.tipo;
        this.descrizione = corridoio.descrizione;
        this[corridoio.id] = [];
        if(corridoio[corridoio.id]){
            for(let stanza of corridoio[corridoio.id]) {
                this[corridoio.id].push(new Stanza(stanza));
            }
        }
        
    }
}

class Stanza {
    constructor(stanza) {
        this.id_thinkin = stanza.id_zona_thinkin;
        this.id = stanza.id;
        this.tipo = stanza.tipo;
        this.descrizione = stanza.descrizione;
        this.num_letti = stanza.num_letti;
        this.id_residenti = stanza.id_residenti;
        this.letti = stanza.letti;
    }
}

class GetStrutturaInfo {
    constructor(json) {
        this.id = json.id;
        this.nome = json.nome;
        this.indirizzo = json.indirizzo ?? 'None';
    }
}

export { Piano, Ala, Corridoio, Stanza, GetStrutturaInfo };