import axios from 'axios';
import * as Piano from './piani_model.js';
import * as Auth from '../auth/auth.js';



async function addPiano(token, id, descrizione) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovoPiano = new Piano.AddPiano(id, descrizione)

        const response = await axios.post(`${Auth.default.url}/piani`, JSON.stringify(nuovoPiano.toJson()), { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function deletePiano(token, id_piano) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/piani/${id_piano}`, { headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function putAlainPiano(token, id_piano, id_ala) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.put(`${Auth.default.url}/piani/${id_piano}/ale/${id_ala}`, {}, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function deleteAlainPiano(token, id_piano, id_ala) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/piani/${id_piano}/ale/${id_ala}`, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function editDescrizione(token, id_piano, desc) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.patch(`${Auth.default.url}/piani/${id_piano}`, desc, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { addPiano, deletePiano, putAlainPiano, deleteAlainPiano, editDescrizione };