import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import sensoriNode from 'node/sensori/sensori.js';
import getVayyarInfo  from 'node/vayyarinfo/vayyarinfo';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].notifiche;
const accountData = JSON.parse(localStorage.getItem("account"));
export default function Index(props) {
    const [sort, setSort] = useState('nome');
    const [order, setOrder] = useState('asc');
    const [sensori, setSensori] = useState([]);
    const [sensoriunivoci, setSensoriUnivoci] = useState([]);

    useEffect(() => {
        //instant call async function
            getdata("asc", "asc");
    } ,[])

    async function Sort(sorting) {
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        await getdata(sortBy, orderBy);
    }

    async function getdata(sorting, ordeBy) {
        
        await sensoriNode.getSensori(accountData.token, sorting, ordeBy).then((response) => {
            if(!response.result) throw response.error;
            setSensori(response.data);
            console.log(response.data);
            parsesensorID(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
        });
        return;
    }
    async function parsesensorID(data) {
        let sensorID = [];
        let sensorList = [];
        data.map((sensore) => {
            //per ogni sensore rimuovi il refisso "fall-" o "presence-" e aggiungi un oggetto alla lista {percorso: "" id: ""}, se non è già presente
            let id = sensore.id;
            let percorso = sensore.percorso;
            let nome = sensore.nome;
            let sensoreunivoco = {id: id, percorso: percorso, nome: nome};
            if (id.includes("fall-")) {
                id = id.replace("fall-", "");
                sensoreunivoco.id = id;
                sensoreunivoco.percorso = percorso;
                
            } else if (id.includes("presence-")) {
                id = id.replace("presence-", "");
                sensoreunivoco.id = id;
                sensoreunivoco.percorso = percorso;
            }
            if (!sensorID.some((sensore) => sensore.id === sensoreunivoco.id)) {
                sensorID.push(sensoreunivoco);
                sensorList.push(sensoreunivoco.id);
            }
            console.log(sensorID);

            
        });
        console.log(sensorList);
        getVayyarInfo(accountData.token, sensorList).then((response) => {
            if(!response.result) throw response.error;
            //console.log(response.data);
            sensorID.map((sensore) => {
                response.data.map((vayyar) => {
                    if (sensore.id === vayyar.id) {
                        sensore.mac = vayyar.mac;
                        sensore.rssi = vayyar.rssi;
                        sensore.tipo = vayyar.anchormac;
                    }
                }
                )
            }
            )
            console.log(response.data);
            setSensoriUnivoci(sensorID);
        }).catch((error) => {
            console.log(error);
        });
        console.log(sensorID);
        return sensorID;
    }
    return ( 
        <>
        <div id="loading" />
        <div id="ContentPage"  style={{ height : "calc(100% - 4.5em)" }}>
        <table  id="TitoliLista">
                    <tbody>
                        <tr>
                            <td width="17.5%" >ID </td>
                            <td width="18%">{"Posizione"}</td>
                            <td width="10%">RSSI</td>
                            <td width="20%">MAC</td>
                            <td width="30%">MAC Antenna</td>
                        </tr>
                    </tbody>
                </table>

    {sensoriunivoci === undefined ?
        <div id="noData">
            <p>{langSet.erroreCaricamento}</p>
        </div>
        : sensoriunivoci.map((sensore, index) => {
            return (
                <table  id="Lista" key={index}>
                    <tbody>
                        <tr>
                            
                            <td width="17.5%">{sensore.id}</td>
                            <td width="18%" style={{ fontSize: "90%" }}>{sensore.percorso}</td>
                            <td width="10%">{sensore.rssi+ "db"}</td>
                            <td width="20%">{sensore.mac}</td>
                            <td width="30%">{sensore.anchormac}</td>


                        </tr>
                    </tbody>
                </table>
            );
        })
    }
    </div>
    </>


    );
}