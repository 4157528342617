

class GetProfilo {
    constructor(json){
        this.id = json.id;
        this.descrizione = json.descrizione;
    }
}



class CreaProfilo {
    constructor( descrizione, tipo, id_allarmi){
        this.descrizione = descrizione;
        this.tipo = tipo;
        this.id_allarmi = id_allarmi;
    } 

    toJson(){
        return {
            descrizione: this.descrizione,
            tipo: this.tipo,
            id_allarmi: this.id_allarmi
        }
    }
}

export { GetProfilo, CreaProfilo };