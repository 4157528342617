

class GetAllarme {
    constructor(json){
        this.id = json.id;
        this.descrizione = json.descrizione;
        this.giorni = json.giorni;
        this.inizio = json.inizio;
        this.fine = json.fine;
        this.valore = json.valore;
    }
    toJson(){
        return {
            id: this.id,
            giorni: this.giorni,
            inizio: this.inizio,
            fine: this.fine,
            descrizione: this.descrizione,
            valore: this.valore,
        }
    }
}

class GetAllarmeLetto {
    constructor(json) {
        this.id_vuoto = json.id_vuoto;
        this.id_occupato = json.id_occupato;
        this.descrizione = json.descrizione;
        this.inizio = json.inizio;
        this.fine = json.fine;
        this.letto_vuoto = json.letto_vuoto ?? 'Nessun allarme';
        this.letto_occupato = json.letto_occupato ?? 'Nessun allarme';
    }
}

class GetAllarmeCaduta {
    constructor(json) {
        this.id = json.id;
        this.descrizione = json.descrizione;
        this.inizio = json.inizio;
        this.fine = json.fine;
        this.stato = json.stato;
    }
}

class getAllarmiOccupazioneStanza {
    constructor(json) {
        this.id = json.id;
        this.descrizione = json.descrizione;
        this.inizio = json.inizio;
        this.fine = json.fine;
        this.stato = json.stato;
    }
}



class PostAllarmiSensore {
    constructor(id_sensore, allarmi){
        this.id_sensore = id_sensore;
        this.allarmi = allarmi;
    }
    toJson(){
        return {
            id_sensore: this.id_sensore,
            allarmi: this.allarmi
        }
    }
}

class StandardAllarmi{
    constructor(json){
        this.id = json.id;
        this.modello = json.modello;
        this.tipo = json.tipo;
        this.valori = json.valori;
    }
}


class Allarme {
    constructor (giorni, inizio, fine, descrizione, abilitato, id_piani, messaggio, tipo, lingua, valore, ritardo){
        this.giorni = giorni;
        this.inizio = inizio;
        this.fine = fine;
        this.descrizione = descrizione;
        this.abilitato = abilitato;
        this.id_piani = id_piani;
        this.messaggio = messaggio;
        this.tipo = tipo;
        this.lingua = lingua;
        this.valore = valore;
        this.ritardo = ritardo;
    }
    toJson(){
        return {
            giorni: this.giorni,
            inizio: this.inizio,
            fine: this.fine,
            descrizione: this.descrizione,
            abilitato: this.abilitato,
            id_piani: this.id_piani,
            messaggio: this.messaggio,
            tipo: this.tipo,
            lingua: this.lingua,
            valore: this.valore,
            ritardo: this.ritardo
        }
    }
}




export { GetAllarme, StandardAllarmi, Allarme, PostAllarmiSensore, GetAllarmeLetto, GetAllarmeCaduta, getAllarmiOccupazioneStanza};