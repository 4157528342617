import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
// import DataTable from 'react-data-table-component';

import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import BackArrowtoFirst from '../../imgs/icons/back-arrow-to-first.svg';

import PopupComp from '../../components/Popup.js';

import notificheNode from 'node/notifiche/notifiche.js';
import sensoriNode from 'node/sensori/sensori.js';

import lang from "../../lang.json";
import { CSVLink, CSVDownload } from "react-csv";
import falldetected from "../../imgs/icons/falldetected.svg";
import emptyroom from "../../imgs/icons/emmptyroom.svg";

const langSet = lang[localStorage.getItem("lang")].notifiche;



export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [sort, setSort] = useState('creato');
    const [order, setOrder] = useState('asc');
    const [notifiche, setNotifiche] = useState([]);
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [page, setPage] = useState(0);
    const [sensori, setSensori] = useState([]);

    const [data, setData] = useState([]);

    const headers = [
        { label: "Tipologia allerta", key: "tipologiaAllerta" },
        { label: "Dove", key: "dove" },
        { label: "Data e ora", key: "date" }
    ];

    async function Sort(sorting) { // sorting = nome colonna
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        await getNot(sortBy, orderBy);
        await getNotjson(sortBy, orderBy);
        props.loading(false);
    }

    async function getNot(sorting, orderBy) { // get per le notifiche
        props.loading(true);
        await notificheNode.getNotifiche(accountData.token, sorting, orderBy).then((res) => {
            if(!res.result) throw res.error;
            setNotifiche(res.data);

            const jsonData = res.data.map((notifica, index) => {
                return {
                    "tipologiaAllerta": notifica.messaggio === 'Il paziente è caduto' ? 'Possibile caduta' : notifica.messaggio === 'Il letto è vuoto' ? 'Uscita letto' : notifica.messaggio,
                    "dove": notifica.residente !== '' ? notifica.residente.replace('Stanza', 'Letto') : notifica.id_stanza,
                    "date": notifica.creato
                };
            });
            console.log(jsonData);
            setData(jsonData);
        });
        props.loading(false);
    }

    async function getNotjson(sorting, orderBy) { // get per le notifiche
        props.loading(true);
        await notificheNode.getNotifiche(accountData.token, sorting, orderBy).then((res) => {
            if(!res.result) throw res.error;

            if (notifiche === undefined) {
                return (
                    <div id="noData">
                        <p>{langSet.erroreCaricamento}</p>
                    </div>
                );
            } else {

            }

            const jsonData = notifiche.map((notifica, index) => {
                return {
                    "tipologiaAllerta": notifica.messaggio === 'Il paziente è caduto' ? 'Possibile caduta' : notifica.messaggio === 'Il letto è vuoto' ? 'Uscita letto' : notifica.messaggio,
                    "dove": notifica.residente !== '' ? notifica.residente.replace('Stanza', 'Letto') : notifica.id_stanza,
                    "date": notifica.creato
                };
            });

            console.log(jsonData);

            setData(jsonData);
        });
        props.loading(false);
    }


    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            setSort(props.sortBy);
            setOrder(props.orderBy);
            getNot(props.sortBy, props.orderBy);
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) { // per aprire una scheda dentro una scheda
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    useEffect(() => {
        getSensori();
    }, []);

    async function getSensori() { // get per i sensori
        props.loading(true);
        await sensoriNode.getSensori(accountData.token).then((res) => {
            if(!res.result) throw res.error;
            setSensori(res.data);
        });
        props.loading(false);
    }

    function getNomeSensore(id) { // prende il nome del sensore
        let nome = '';
        sensori.forEach((sensore) => { // prende il nome del sensore
            if (sensore.id === id) {
                nome = sensore.nome;
            }
        });
        return nome;
    }

    return (
        <>
            <div id="loading"/>
            <div id="Popup">
                {/*<div onClick={() => centraPopup()} id="options">*/}
                {/*    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}*/}
                 {/*</div>*/}
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getNot={getNot} closePopup={closePopup} loading={props.loading}
                                   SchedaInScheda={SchedaInScheda}/>
                    </div>
                </Draggable>
            </div>

            {/*<div style={{display: "flex", justifyContent: "flex-end", marginTop: "10px", marginRight: "10px"}}>*/}
            {/*    <div>*/}
            {/*        <CSVLink data={data} headers={headers} style={{backgroundColor: "red", width: "100%"}}>*/}
            {/*            Esporta in csv*/}
            {/*        </CSVLink>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div id="table-scroll" className="table-scroll table-scroll2"
                 style={{height: "calc(100% - 4.5em)", marginTop: "1rem"}}>
                <table id="TitoliLista" className="main-table" style={{width: "100%"}}>
                    <thead style={{
                        borderTop: "1px solid #F5F5F5",
                        borderBottom: "1px solid #CDDEFF",
                        borderRadius: "10px"
                    }}>
                    <tr style={{color: "#4DA6DD"}}>


                        {/*<th style={{zIndex: 100, borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}}*/}
                        {/*    className={sort === 'messaggio' ? 'selected' : null} colspan="2" >*/}
                        {/*    /!*{langSet.messaggio}*!/*/}
                        {/*    &nbsp;</th>*/}

                        <th style={{zIndex: 100, borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}} colspan="2"
                            className={sort === 'messaggio' ? 'selected' : null} onClick={() => Sort('messaggio')}>
                            {/*{langSet.messaggio}*/}
                            Tipologia allerta
                            &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli white'
                                        id='Arrowmessaggio'/></th>
                        <th style={{zIndex: 100}} className={sort === 'residente' ? 'selected' : null}
                            onClick={() => Sort('residente')}>
                            {/*{langSet.residente}*/}
                            Dove
                            &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowresidente'/></th>
                        {/*<th style={{ zIndex:100 }} >{langSet.stanza} </th>*/}
                        <th style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px", zIndex: "100"}}
                            className={sort === 'creato' ? 'selected' : null} onClick={() => Sort('creato')}>
                            {/*{langSet.creatoIl} */}
                            Data e ora
                            &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowcreato'/></th>

                    </tr>
                    </thead>

                    <tbody style={{fontSize: "medium", color: "black"}} className="tablestraip">

                    {notifiche === undefined ?
                        <div id="noData">
                            <p>{langSet.erroreCaricamento}</p>
                        </div>
                        : notifiche.slice(page * 30, (page + 1) * 30).map((notifica, index) => {

                            console.log({notifica});

                            return (
                                <tr id="Lista" key={index} className={notifica.confermata ? "" : "allarme alerta bold"}
                                    style={{cursor: "pointer"}} onClick={() => openPopup('scheda_notifica', notifica)}>

                                    <td style={{textAlign: "center"}}>

                                        {notifica.messaggio === 'Il paziente è caduto' ? ( <img src={falldetected} alt="allarme"/> ) : notifica.messaggio === 'Il letto è vuoto' ? (

                                            notifica.confermata ?

                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13 20.2857C13 19.0233 14.0233 18 15.2857 18H15.5455C18.5579 18 21 20.4421 21 23.4545V23.4545C21 23.7558 20.7558 24 20.4545 24H13.7826C13.3504 24 13 23.6496 13 23.2174V20.2857Z"
                                                    fill="#C9CACE"
                                                />
                                                <path
                                                    d="M40 26.3435V26.3332H37.315H12.7589H12.685V16.4739C12.685 15.6599 12.1027 15 11.3845 15H11.3005C10.5823 15 10 15.6599 10 16.4739V32.6498H12.685V29.3762H37.315V32.6498H40V29.3762V26.3529V26.3435Z"
                                                    fill="#C9CACE"
                                                />
                                                <path
                                                    d="M39 21.5784C39 20.3925 38.0255 19.4313 36.8236 19.4313L25.1764 18C23.9745 18 23 18.9612 23 20.147V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H37.4C37.9601 24 38.2401 24 38.454 23.891C38.6422 23.7951 38.7951 23.6422 38.891 23.454C39 23.2401 39 22.9601 39 22.4V21.5784Z"
                                                    fill="#C9CACE"
                                                />
                                                <text x="25" y="25" fontSize="24" fill="rgb(215, 24, 24)" fontWeight="bold" color="rgb(215, 24, 24)" >
                                                    !
                                                </text>
                                            </svg>:
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13 20.2857C13 19.0233 14.0233 18 15.2857 18H15.5455C18.5579 18 21 20.4421 21 23.4545V23.4545C21 23.7558 20.7558 24 20.4545 24H13.7826C13.3504 24 13 23.6496 13 23.2174V20.2857"
                                                    fill="#333333"
                                                />
                                                <path
                                                    d="M40 26.3435V26.3332H37.315H12.7589H12.685V16.4739C12.685 15.6599 12.1027 15 11.3845 15H11.3005C10.5823 15 10 15.6599 10 16.4739V32.6498H12.685V29.3762H37.315V32.6498H40V29.3762V26.3529V26.3435Z"
                                                    fill="#333333"
                                                />
                                                <path
                                                    d="M39 21.5784C39 20.3925 38.0255 19.4313 36.8236 19.4313L25.1764 18C23.9745 18 23 18.9612 23 20.147V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H37.4C37.9601 24 38.2401 24 38.454 23.891C38.6422 23.7951 38.7951 23.6422 38.891 23.454C39 23.2401 39 22.9601 39 22.4V21.5784Z"
                                                    fill="#333333"
                                                />
                                                <text x="25" y="25" fontSize="24" fill="rgb(215, 24, 24)" fontWeight="bold" color="#rgb(215, 24, 24)">
                                                    !
                                                </text>
                                            </svg>


                                        ) : notifica.messaggio === 'la stanza è vuota' ? (
                                            notifica.confermata ? <img src={emptyroom} alt="libero"/> :
                                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13 20.2857C13 19.0233 14.0233 18 15.2857 18H15.5455C18.5579 18 21 20.4421 21 23.4545V23.4545C21 23.7558 20.7558 24 20.4545 24H13.7826C13.3504 24 13 23.6496 13 23.2174V20.2857"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M40 26.3435V26.3332H37.315H12.7589H12.685V16.4739C12.685 15.6599 12.1027 15 11.3845 15H11.3005C10.5823 15 10 15.6599 10 16.4739V32.6498H12.685V29.3762H37.315V32.6498H40V29.3762V26.3529V26.3435Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M39 21.5784C39 20.3925 38.0255 19.4313 36.8236 19.4313L25.1764 18C23.9745 18 23 18.9612 23 20.147V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H37.4C37.9601 24 38.2401 24 38.454 23.891C38.6422 23.7951 38.7951 23.6422 38.891 23.454C39 23.2401 39 22.9601 39 22.4V21.5784Z"
                                                        fill="#333333"
                                                    />
                                                </svg>
                                        ) : null}
                                    </td>

                                    <td style={{textAlign: "start"}}>{notifica.messaggio == 'Il paziente è caduto' ? 'Possibile caduta' : notifica.messaggio == 'Il letto è vuoto' ? 'Uscita letto' : notifica.messaggio}</td>
                                    {/*to do valore tempreneo */}
                                    {/*<td style={{textAlign: "center"}}>{notifica.residente.replace('Stanza', 'Letto')}</td>*/}
                                    <td style={{textAlign: "center"}}>{notifica.residente != '' ? notifica.residente.replace('Stanza', 'Letto') : notifica.id_stanza}</td>
                                    {/*<td style={{textAlign: "center"}}>{notifica.id_stanza}</td>*/}
                                    <td style={{textAlign: "center"}}>{notifica.creato}</td>
                                    {/*id_stanza*/}
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>

            <div id="Header" style={{width: "100%"}}>
                <div>
                    <CSVLink data={data} headers={headers} className="selettore selettoreFlex"
                             filename="Export_allerte.csv"
                             style={{
                                 background: "#00A3DF",
                                 color: "white",
                                 textDecoration: "none",
                                 marginRight: "20px",
                                 marginLeft: "0px"
                             }}>
                        <p>Esporta in csv</p>
                    </CSVLink>
                </div>
                {notifiche ?
                    <>
                        <div className='headerDX' style={{marginRight: "10px"}}>
                        <div className='selettore selettoreFlex' style={{cursor: 'default'}}>

                                {Math.ceil(notifiche.length / 30) > 1 && (
                                    <img src={BackArrowtoFirst}
                                         style={{width: '1.2rem', margin: '4px'}}
                                         onClick={() => setPage(0)}/>
                                )}
                                <img
                                    src={arrowPurple}
                                    style={{transform: "rotate(90deg)", width: '1.2rem', margin: '4px'}}
                                    onClick={() => page > 0 ? setPage(page - 1) : null}
                                />
                                <div style={{
                                    fontWeight: 200,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: 'default'
                                }}>{langSet.pagina}&nbsp;{page + 1} / <p
                                    onClick={() => setPage(Math.ceil(notifiche.length / 30) - 1)}>{Math.ceil(notifiche.length / 30)}</p>
                                </div>
                                <img
                                    src={arrowPurple}
                                    style={{transform: "rotate(-90deg)", width: '1.2rem', margin: '4px'}}
                                    onClick={() => page < Math.ceil(notifiche.length / 30) - 1 ? setPage(page + 1) : null}
                                />
                                {Math.ceil(notifiche.length / 10) > 1 && (
                                    <img src={BackArrowtoFirst}
                                         style={{transform: "rotate(180deg)", width: '1.2rem', margin: '4px'}}
                                         onClick={() => setPage(Math.ceil(notifiche.length / 30) - 1)}/>
                                )}
                            </div>
                        </div>
                    </> :
                    <>
                    </>
                }
            </div>

        </>
    );
}