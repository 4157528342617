import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import arrow from "../imgs/icons/arrowW.svg";
import arrowFull from "../imgs/icons/arrowFull.svg";
import close from "../imgs/icons/close.svg";
import userPurple from "../imgs/icons/userPurple.svg";
import residentiPurple from "../imgs/icons/residentiPurple.svg";
import delet from '../imgs/icons/delete.svg';
import confirmW from '../imgs/icons/confirmW.svg';

import magazzinoNode from 'node/magazzino/magazzino.js';
import stanzeNode from "node/stanze/stanze.js";
import residentiNode from "node/residenti/residenti.js";


import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_residente;

const NuovoResidente = (props) => {
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [id, setId] = useState("");
    const [status, setStatus] = useState(0);
    const [stanze, setStanze] = useState([]);
    const [camera, setCamera] = useState(0);
    const [sensori, setSensori] = useState([]);
    const [sensoriSel, setSensoriSel] = useState([]);
    const [tipoSensori, setTipoSensori] = useState([]);
    const sort = "id";
    const order = "asc";
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [idLetto, setIdLetto] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
const filteredOptions = sensori.filter((sensore) => { // filtra i sensori che hanno lo stesso tipo di quelli già selezionati in sensoriSel
    return !tipoSensori.includes(sensore.tipo);
    
    })
  ? sensori.filter((sensore) => { // filtra i sensori che hanno lo stesso tipo di quelli già selezionati in sensoriSel
    return !tipoSensori.includes(sensore.tipo);
    
    }).filter((sensore) => {
        console.log(sensore);

        const sensoreText = `${sensore.nome} - ${sensore.tipo}`;
        return sensoreText.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map((sensore) => ({
        value: sensore.id,
        label: `${sensore.nome} - ${sensore.tipo}`,
      }))
  : [];


    useEffect(() => {
        setStatus(0);
        setId("");
        setNome("");
        setCognome("");
        setCamera(0);
        setSensoriSel([]);
    }, [props]);

    function init() {
        setStatus(0);
        setId("");
        setNome("");
        setCognome("");
        setCamera(null);
        setSensoriSel([]);
        setTipoSensori([]);
        getLibere()
        getSensori()
        setIdLetto(null);
    }
    const identificativoLetto = [
        {
        id: "A", value: "A"
        },
        {
        id: "B", value: "B"
        },
        {
        id: "C", value: "C"
        },
        {
        id: "D", value: "D"
        },
]

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder noCursor";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow noCursor";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    
    async function getLibere() { // funzione che assegna la stanza alla variabile camera
        await stanzeNode.getStanzeLibere(accountData.token).then((res) => { // funzione che prende le stanze libere
            setStanze(res.data); // assegna le stanze libere alla variabile stanze
        });
    }
   
    
    async function getSensori() { // funzione che assegna i sensori alla variabile sensori
        await magazzinoNode.getMagazzinoStruttura(accountData.token, sort, order).then((res) => {   // funzione che prende i sensori
            setSensori(res.data); // assegna i sensori alla variabile sensori
        });
    }
    

    function Sens(azione, id) {     // funzione che aggiunge o toglie i sensori
        if (azione === 'add') {    // se azione è add
            setSensoriSel([...sensoriSel, { id: id, alarms: [] }]); // aggiunge il sensore alla variabile sensoriSel
            setTipoSensori([...tipoSensori, sensori.filter((item) => item.id === id)[0].tipo]); // aggiunge il tipo del sensore alla variabile tipoSensori
        } else {
            setSensoriSel(sensoriSel.filter((item) => item !== id));    // rimuove il sensore dalla variabile sensoriSel
            setTipoSensori(tipoSensori.filter((item) => item !== sensori.filter((item) => item.id === id)[0].tipo));    // rimuove il tipo del sensore dalla variabile tipoSensori
        }
    }

    async function addResidente() {
        props.loading(true);   // imposta il caricamento a true
        setStatus(3);  // imposta lo status a 3
        await residentiNode.addResidente(accountData.token, id + idLetto, nome, cognome, camera, sensoriSel).then(() => { // funzione che aggiunge il residente
            props.getRes().finally(() => {  // funzione che prende i residenti
                setStatus(0);   // imposta lo status a 0
                init() // inizializza le variabili
                props.closePopup();  
                  // chiude il popup
                props.loading(false);  // imposta il caricamento a false
            });
        });
    }

    useEffect(() => {  // funzione che viene eseguita quando cambia lo status
        getLibere();  // funzione che prende le stanze libere
        getSensori(); // funzione che prende i sensori
    }, []);

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto" }}> {/* header del popup */}
                <div className={status === 0 ? selSelettore : defSelettore}> {/* se status è 0 allora il selettore è selezionato altrimenti è disabilitato */}
                    <p>{langSet.datiPersonali}</p>  {/* testo del selettore */}
                </div>  {/* fine selettore */}
                <div className={status > 1 ? defSelettore : status === 1 ? selSelettore : "disabled " + defSelettore}>  {/* se status è 1 allora il selettore è selezionato altrimenti è disabilitato */}
                    <p>{langSet.stanza}</p> {/* testo del selettore */}
                </div>  {/* fine selettore */}
                <div className={status > 2 ? defSelettore : status === 2 ? selSelettore : "disabled " + defSelettore}>  {/* se status è 2 allora il selettore è selezionato altrimenti è disabilitato */}
                    <p>{langSet.sensoriPersonali}</p> {/* testo del selettore */}
                </div>  {/* fine selettore */}
            </div>  {/* fine header del popup */}
            <div id="PopupBody">    {/* corpo del popup */}
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>  {/* tabella che contiene i dati personali */}
                    <tbody> {/* corpo della tabella */}
                        <tr>    {/* riga della tabella */}
                            <td width="15%" rowSpan={2} className="schedaImg">   {/* colonna della tabella */}
                                <img src={userPurple} alt="position" />     {/* immagine */}
                            </td>   {/* fine colonna */}
                            <td width="17.5%">  {/* colonna della tabella */}
                                <label>{langSet.nome}</label>   {/* testo */}
                                <input  
                                    type="text" 
                                    placeholder={langSet.inserisciNome}    
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />  {/* input */}
                            </td>
                            <td width="17.5%">
                                <div>
                                    <label>{langSet.cognome}</label>
                                    <input
                                        type="text"
                                        value={cognome}
                                        placeholder={langSet.inserisciCognome}
                                        onChange={(e) => setCognome(e.target.value)}
                                    />
                                </div>
                            </td>
                            <td width="50%" valign="bottom" rowSpan={2}>
                                <div
                                    className={nome !== "" && cognome !== "" && id !== "" && idLetto !== "" ? defContinua : "disabled " + defContinua} // se nome, cognome e id sono diversi da "" allora il bottone è abilitato altrimenti è disabilitato
                                    onClick={nome !== "" && cognome !== "" && id !== "" && idLetto !== "" ? () => setStatus(1) : () => { }}   // se nome, cognome e id sono diversi da "" allora il bottone è abilitato altrimenti non fa nulla
                                >
                                    <p>{langSet.continua}</p>
                                    <img src={arrow} alt='arrow' style={{ transform: "rotate(-90deg)" }} /> 
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}> {/* bottone che chiude il popup */}
                                    <p>{langSet.annulla}</p>
                                    <img className="icon-popup" src={close} alt="close" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>{langSet.identificativo}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciIdentificativo}
                                    value={id}
                                    onChange={(e) => setId(e.target.value)}
                                />
                            </td>

                            <td>
                                <label>{"Identificativo Letto"}</label>
                                {/*seleziona uno dei valori della variabile IdentiticativiLetto */}
                                <select onChange={(e) => setIdLetto(e.target.value)} defaultValue={0}>
                                    <option value={0} disabled>{langSet.selezionaIdentificativo}</option>
                                    {identificativoLetto.map((identificativo, index) => {
                                        return (
                                            <option key={index} value={identificativo.id}>
                                                {identificativo.value}
                                            </option>
                                        );
                                    }
                                    )}  
                                </select>
                            </td>

                            
                        </tr>
                    </tbody>
                </table>
                <table className="scheda" style={{ display: status === 1 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={residentiPurple} alt="position" />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.camera}</label>
                                <select onChange={(e) => setCamera(e.target.value)} defaultValue={0}>
                                    <option value={0} disabled>{langSet.selezionaCamera}</option>
                                    {stanze === undefined ?
                                        <option disabled>{langSet.erroreCaricamento}</option>
                                        : stanze.map((stanza, index) => {
                                            return (
                                                <option key={index} value={stanza}>
                                                    {stanza}
                                                </option>
                                            );
                                        })} {/* se stanze è undefined allora mostra un'opzione disabilitata altrimenti mostra tutte le stanze */}
                                </select>
                            </td>
                            <td valign="bottom" > testst
                                <div
                                    className={camera !== 0 ? defContinua : "disabled " + defContinua}
                                    onClick={camera !== 0 ? () => setStatus(2) : () => { }}  // se camera è diverso da 0 allora il bottone è abilitato altrimenti non fa nulla
                                >
                                    <p>{langSet.continua}</p> {/* testo del bottone */}
                                    <img src={arrow} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                </div>
                                <div
                                    className="selettore selettoreFlex selettoreViolet"
                                    onClick={() => setStatus(0)} // se clicco sul bottone allora il popup torna allo stato 0
                                >
                                    <p>{langSet.indietro}</p>
                                    <img src={arrowFull} alt='arrow' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: status === 2 ? "" : "none", height: "20%" }}> {/* se status è 2 allora mostra il contenuto altrimenti non mostra nulla */}
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="12.5%" style={{ border: 0 }}>{langSet.nome} </td>
                                <td width="12.5%" style={{ border: 0 }}>ID </td>
                                <td width="12.5%" style={{ border: 0 }}>{langSet.tipo} </td>
                                <td width="7.5%" style={{ border: 0 }}>{langSet.stato} </td>
                                <td width="7.5%" style={{ border: 0 }}>{langSet.batteria} </td>
                                <td width="47.5%" style={{ border: 0 }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <Select id="Select"
                        styles={{
                            control: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", overflowX: "hidden", boxShadow: "none", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),
                            input: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px" }),
                            menu: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", boxShadow: "none",  border: "solid 1px #2B318A", overflowX: "hidden", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),
                            option: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px", overflowY: "scroll", maxHeight: "25em", overflowX: "hidden"}),
                          }}
                        options={filteredOptions}
                        value={selectedOption}
                        onChange={(selected) => {setSelectedOption(selected); Sens("add", selected.value)}}
                        placeholder={langSet.selezionaSensore}
                        isSearchable
                        onInputChange={(inputValue) => setSearchTerm(inputValue)}
                        /><i style={{ fontSize: "90%", color: "#d3d3d3" }}>* {langSet.massimo1PerTipologia}</i>
                    <div style={{ overflow: "scroll", maxHeight: "25em" }}>
                        {sensoriSel.reverse().map((sensore, i) => (
                            <table id="Lista" style={{ border: 0 }} key={i}>
                                <tbody>
                                    <tr key={sensore}>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).nome}</td>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.id}</td>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).tipo}</td>
                                        <td width="7.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).stato}</td>
                                        <td width="7.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).batteria}</td>
                                        <td width="47.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => Sens("del", sensore)}>
                                                <p>{langSet.rimuovi}</p>
                                                <img className="icon-popup" src={delet} alt="delete" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))} {/* mostra tutti i sensori selezionati */}
                    </div>
                    <div className="navigators" style={{marginTop:"20%"}}>
                        <div
                            className={defContinua}
                            onClick={() => addResidente()}
                        >
                            <p>{langSet.salvaEChiudi}</p>
                            <img src={confirmW} alt='arrow' />
                        </div>
                        <div
                            className="selettore selettoreFlex selettoreViolet"
                            onClick={() => setStatus(1)}
                        >
                            <p>{langSet.indietro}</p>
                            <img src={arrowFull} alt='arrow' />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default NuovoResidente;