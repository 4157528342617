import axios from 'axios';
import * as Accesso from './auth_model.js';

let url = '';
if(process.env.REACT_APP_NODE == "production") {
  url = 'https://dashboard.care4u.it/api';
} else {
  url = 'https://test-dashboard.care4u.it/api';
}

/*--------------------- SIGN IN ---------------------*/
async function signIn(email, password) {
  try {
    const headers = { 'Content-Type': 'text/plain' };

    const logInCredentials = new Accesso.LoginModule(email, password);

    let logindata = JSON.stringify(logInCredentials.toJson());

    const encryptedData = logindata;

    const response = await axios.post(`${url}/auth/sign-in`, encryptedData, { headers });
    console.log(response)
    
    const decryptedData = response.data;

    const tokenModule = new Accesso.TokenModule(response.data);

    return { result: true, data: tokenModule };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getUsers(token, sort, orderby) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.get(`${url}/auth/all`, { params: { sortby: sort, orderby: orderby }, headers });

    let utenti = response.data;

    let data = [];

    for (let utente of utenti) {
      data.push(new Accesso.Utente(utente));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*---------------------  SIGN UP (Disponibile a seconda del ruolo)) ---------------------*/
async function newUser(token, nome, cognome, email, password, tipo) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const nuovoUtente = new Accesso.SignUpModule(nome, cognome, email, password, tipo);

    const response = await axios.post(`${url}/auth/sign-up`, JSON.stringify(nuovoUtente.toJson()), { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*---------------------  EDIT UTENTE => SI POSSONO MODIFICARE SOLO NOME E COGNOME ---------------------*/
//! BISOGNA ESSERE ADMIN
async function editUser(token, user_id, data) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.patch(`${url}/auth/${user_id}`, JSON.stringify(data), { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*---------------------  DELETE USER (Disponibile a seconda del ruolo)) ---------------------*/
async function deleteUser(token, user_id) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.delete(`${url}/auth/${user_id}`, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function switchStruttura(token, db) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.patch(`${url}/auth/struttura/${db}`, {}, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { signIn, getUsers, newUser, editUser, deleteUser, switchStruttura, url };