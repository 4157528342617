import axios from 'axios';
import * as Notifiche from './notifiche_model.js';
import * as Auth from '../auth/auth.js';


/*--------------------- OTTENGO LA LISTA DI TUTTE LE NOTIFICHE ---------------------*/
async function getNotifiche(token, sort, orderby){
    try{
        const headers = { 'Authorization': `Bearer ${token}` };
        
        const response = await axios.get(`${Auth.default.url}/notifiche/all`, {params: {sortby: sort, orderby: orderby }, headers });
        let notifiche = response.data;

        let data = [];

        for(let notifica of notifiche) {
            data.push(new Notifiche.GetNotificheModel(notifica));
        }

        return {result: true, data: data};
    } catch(e) {
      if(axios.AxiosError && e.response){
        return {result: false, error: e.response.data};
      } else {
        return { result: false, error: e.toString() };
      }
  }
}

/*--------------------- CONFERMO NOTIFICA ---------------------*/
async function patchStatoNotifica(token, id_notifica){
  try{    
    const headers = { 'Authorization': `Bearer ${token}`};
    
    const response = await axios.patch(`${Auth.default.url}/notifiche/${id_notifica}/conferma`, {}, { headers });
    
    return { result: true, message: response.data };    
  } catch(e) {
    if(axios.AxiosError && e.response){
      return {result: false, error: e.response.data};
    } else {
      return { result: false, error: e.toString() };
    }
  }
}
/*--------------------- CONFERMO TUTTE LE NOTIFICHE DEL RESIDENTE ---------------------*/
async function patchAllStatoNotifica(token, nota, id_residente, id_stanza){
  try{    
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain'};
    const nuovaNota = new Notifiche.PutNoteNotificaeresidente(nota, id_residente, id_stanza);
    console.log(nuovaNota);
    
    const response = await axios.patch(`${Auth.default.url}/notifiche/confirmall/conferma`,JSON.stringify(nuovaNota.toJson()), { headers });
    
    return { result: true, message: response.data };    
  } catch(e) {
    if(axios.AxiosError && e.response){
      return {result: false, error: e.response.data};
    } else { 
      return { result: false, error: e.toString() };
    }
  }
}

async function patchNotificheFeedback(token, notificheList) {
  try{
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain'};
    const response = await axios.patch(`${Auth.default.url}/notifiche/confirmall/conferma`, JSON.stringify(notificheList), { headers });
    return { result: true, message: response.data };
  } catch(e) {
    if(axios.AxiosError && e.response){
      return {result: false, error: e.response.data};
    } else { 
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- AGGIUNGO LA NOTA DELLA NOTIFICA ---------------------*/
async function putNotaNotifica(token, id_notifica, nota){
  try{
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain'};
    
    const nuovaNota = new Notifiche.PutNoteNotifica(nota);
    
    const response = await axios.put(`${Auth.default.url}/notifiche/${id_notifica}/note`, nuovaNota.nota, { headers });
    
    return { result: true, message: response.data };
  } catch(e) {
    if(axios.AxiosError && e.response){
      return {result: false, error: e.response.data};
    } else {
      return { result: false, error: e.toString() };
    }
  }
}


export default { getNotifiche, patchStatoNotifica, putNotaNotifica, patchAllStatoNotifica, patchNotificheFeedback };