import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import addW from '../../imgs/icons/addW.svg';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';
import delet from '../../imgs/icons/delete.svg';

import PopupComp from '../../components/Popup.js';
import auth from "node/auth/auth.js";

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].utenti;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [sort, setSort] = useState(props.sortBy);
    const [order, setOrder] = useState(props.orderBy);
    const [utenti, setUtenti] = useState([]);
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);

    async function Sort(sorting) {
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        getUtenti(sortBy, orderBy);
    }

    async function getUtenti(sortBy, orderBy) {
        props.loading(true);
        await auth.getUsers(accountData.token, sortBy, orderBy).then((res) => {
            if (!res.result) throw res.error;
            setUtenti(res.data);
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            getUtenti();
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);


    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                {/*<div onClick={() => centraPopup()} id="options">*/}
                {/*    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}*/}
                {/*</div>*/}
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getUtenti={getUtenti} closePopup={closePopup} loading={props.loading} SchedaInScheda={SchedaInScheda} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" className="header-alig">
                <div className='headerSX'>
                    <div>
                        <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} />
                    </div>
                    </div>
                <div className='headerDX'>
                    {accountData.tipo === 0 || accountData.tipo === 1 || accountData.tipo === 3 ?
                        <div className='selettore selettoreFlex selettorePurple' style={{ width: "12.5em" }} onClick={() => openPopup('nuovo_utente', '', getUtenti, '')}>
                            <p>{langSet.aggiungiUtente}</p>
                            <img src={addW} alt='add' />
                        </div>
                        : <></>
                    }
                </div>
            </div>
            <div id="ContentPage">
                <table id="TitoliLista">
                    <tbody>
                        <tr>
                            <td width="15%" className={sort === 'nome' ? 'selected' : null} onClick={() => Sort('nome')}>{langSet.nome} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrownome' /> </td>
                            <td width="10%">{langSet.tipo} </td>
                            <td width="38%" className={sort === 'email' ? 'selected' : null} onClick={() => Sort('email')}>Email &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowemail' /> </td>
                            <td width="37%"></td>
                        </tr>
                    </tbody>
                </table>

                {utenti === undefined ?
                    <div id="noData">
                        <p>{langSet.erroreCaricamento}</p>
                    </div>
                    : utenti.map((utente, index) => {
                        return (
                            <table id="Lista" key={index}>
                                <tbody>
                                    <tr>
                                        <td width="15%">{utente.nome + " " + utente.cognome}</td>
                                        <td width="10%">{utente.tipo === 0 ? 'Admin' : utente.tipo === 1 ? langSet.itInterno : utente.tipo === 2 ? langSet.manutEsterno : utente.tipo === 3 ? langSet.coordinatoreInfermiere : utente.tipo === 4 ? langSet.personaleDiCorsia : ''}</td>
                                        <td width="38%">{utente.email}</td>
                                        <td width="37%" align="right">
                                            <div
                                                className={(accountData.email === utente.email ? 'disabled ' : '') + (accountData.tipo === 0 || accountData.tipo === 1 && utente.tipo === 3 || accountData.tipo === 3 && utente.tipo === 4 ? "" : "disabled ") + 'selettore selettoreViolet selettoreFlex'}
                                                onClick={
                                                    accountData.email === utente.email ? null : accountData.tipo === 0 || accountData.tipo === 1 && utente.tipo === 3 || accountData.tipo === 3 && utente.tipo === 4 ? () => openPopup("alarm", langAlarms.lUtente + utente.cognome + " " + utente.nome + langAlarms.verraArchiviato, "deleteUtente", utente) : null
                                                }
                                            //onClick={}
                                            >
                                                <p>{langSet.elimina}</p>
                                                <img className="icon-popup" src={delet} alt='delete' />
                                            </div>
                                            <div className='selettore selettoreBorder selettoreFlex' onClick={() => openPopup("scheda_utente", utente)}>
                                                <p>{langSet.schedaUtente}</p>
                                                <img className="icon-popup" src={arrowPurple} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}
            </div>
        </>
    );
}