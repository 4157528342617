import React, { useState, useEffect } from "react";
import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import modifica from '../imgs/icons/modifica.svg';
import deleteW from '../imgs/icons/deleteW.svg';
import strutturaPurple from "../imgs/icons/strutturaPurple.svg";

import strutturaNode from 'node/struttura/struttura.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_struttura;

const SchedaStruttura = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [mod, setMod] = useState(0);
    const [struttura, setStruttura] = useState({});

    const defContinua = "selettore selettoreFlex selettoreBlue";

    async function editStruttura() {
        props.loading(true);

        await strutturaNode.editStruttura(accountData.token, struttura.nome).then(async (res) => {
            if(!res.result) throw res.error;
            await props.getStrInfo();
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_struttura', props.struttura)).finally(() => {
            props.loading(false);
        });

        return;
    }

    async function delStruttura() {
        props.loading(true);

        await strutturaNode.delStruttura(accountData.token, struttura.nome).then((res) => {
            if(!res.result) throw res.error;
            props.closePopup();

            localStorage.removeItem("login");
            localStorage.removeItem("account");
            localStorage.removeItem("struttura");
            window.location.href = "/";
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_struttura', props.struttura)).finally(() => {
            props.loading(false);
        });

        return;
    }

    useEffect(() => {
        setMod(0);
        setStruttura(props.struttura);
    }, [props]);

    return (
        <>
            <div id="PopupBody">
                <table className="scheda">
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={strutturaPurple} alt="struttura" />
                            </td>
                            <td width="17.5%" className="schedaImg">
                                <label>ID</label>
                                <p>{struttura.id}</p>
                            </td>
                            <td width="15.5%" className="schedaImg">
                                <label>{langSet.nome}</label>
                                {mod === 0 ? <p>{struttura.nome}</p> :
                                    <input
                                        type="text"
                                        value={struttura.nome}
                                        placeholder={langSet.inserisciNome}
                                        onChange={(e) => setStruttura({ ...struttura, nome: e.target.value })}
                                    />
                                }
                            </td>
                            {accountData.tipo === 0 ?
                                <td width="50%" rowSpan={2} valign="bottom">
                                    {mod === 0 ?
                                        <>
                                            <div className={defContinua} onClick={() => delStruttura()}>
                                                <p>{langSet.elimina}</p>
                                                <img src={deleteW} alt='elimina' style={{ width: "1em" }} />
                                            </div>
                                            <div
                                                className="selettore selettoreFlex selettoreViolet"
                                                onClick={() => setMod(1)}>
                                                <p>{langSet.modifica}</p>
                                                <img src={modifica} alt='modifica' style={{ width: "1em" }} />
                                            </div>
                                        </> :
                                        <>
                                            <div className={defContinua} onClick={async () => { await editStruttura(); setMod(0); }}>
                                                <p>{langSet.salva}</p>
                                                <img className="icon-popup" src={confirmW} alt="confirm" />
                                            </div>
                                            <div className="selettore selettoreFlex selettoreViolet" onClick={async () => { await props.getStrInfo(); setMod(0); }}>
                                                <p>{langSet.annulla}</p>
                                                <img className="icon-popup" src={close} alt="close" />
                                            </div>
                                        </>
                                    }
                                </td> :
                                <td width="50%" rowSpan={2} valign="bottom">
                                </td>
                            }

                        </tr>
                        <tr>
                            <td width="17.5%" className="schedaImg">
                                <label>{langSet.indirizzo}</label>
                                <p>{struttura.indirizzo}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SchedaStruttura;