import axios from 'axios';
import * as Crypto from '../crypt/crypt.js';
import * as Profilo from './profili_model.js';
import * as Auth from '../auth/auth.js';



async function getProfili(token, tipo) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        let response = await axios.get(`${Auth.default.url}/profili/${tipo}`, { headers });

        let profili = response.data;

        let data = [];

        for(let profilo of profili){
            data.push(new Profilo.GetProfilo(profilo));
        }

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function creaProfilo(token, descrizione, tipo, id_allarmi) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovoProfilo = new Profilo.CreaProfilo( descrizione, tipo, id_allarmi);

        const response = await axios.post(`${Auth.default.url}/profili`, JSON.stringify(nuovoProfilo.toJson()), { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function eliminaProfilo(token, id_profilo) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/profili/${id_profilo}`, { headers: headers });

        return { result: true, message: response.data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { getProfili, creaProfilo, eliminaProfilo };