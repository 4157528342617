import axios from 'axios';

import * as Residenti from './residenti_model.js';
import * as Auth from '../auth/auth.js';



/*--------------------- OTTENGO LA LISTA DI TUTTI I RESIDENTI E I LORO DATI ---------------------*/
async function getResidenti(token, sort, orderby) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/residenti/all`, { params: { sortby: sort, orderby: orderby }, headers });

    let residenti = response.data;

    let data = [];

    for (let residente of residenti) {
      data.push(new Residenti.GetResidentiAll(residente));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- OTTENGO I DATI DEL SINGOLO RESIDENTE ---------------------*/
async function getResidente(token, id) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/residenti/${id}`, { headers });

    let data = new Residenti.GetResidentiAll(response.data);

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- OTTENGO LA SCHEDA DEL RESIDENTE ---------------------*/
async function getSchedaResidente(token, id_residente) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/residenti/${id_residente}`, { headers });

    let data = new Residenti.GetSchedaResidente(response.data);

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- MODIFICO IL RESIDENTE !!!SOLO PER ADMIN!!! ---------------------*/
async function patchSchedaResidente(token, id_residente, nome, cognome) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const nuoviDati = new Residenti.PatchSchedaResidente(nome, cognome)

    const response = await axios.patch(`${Auth.default.url}/residenti/${id_residente}`, JSON.stringify(nuoviDati.toJson()), { headers });

    return { result: true, data: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- MODIFICO LA STANZA DEL RESIDENTE ---------------------*/
async function patchStanzaResidente(token, id_residente, id_stanza) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.patch(`${Auth.default.url}/residenti/${id_residente}/stanza`, id_stanza, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- OTTENGO I SENSORI DEL RESIDENTE ---------------------*/
async function getSensoriResidente(token, id_residente) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/residenti/${id_residente}/sensori`, { headers });

    let sensori = response.data;

    let data = [];

    for (let sensore of sensori) {
      data.push(new Residenti.GetSensoriResidente(sensore));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function patchSensoreResidente(token, id_residente, id_sensore) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.patch(`${Auth.default.url}/residenti/${id_residente}/sensori/${id_sensore}`, {}, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- OTTENGO GLI ALLARMI DEL RESIDENTE !!!FARE STRUTTURA!!!---------------------*/
async function getAllarmiResidente(token, id_residente) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/allarmi/${id_residente}`, { headers });

    let allarmi = response.data;

    let data = [];

    for (let allarme of allarmi) {
      data.push(new Residenti.GetAllarmiResidente(allarme));
    }

    return { result: true, data: data };
  }
  catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- AGGIUNGO UN NUOVO RESIDENTE ---------------------*/
async function addResidente(token, id, nome, cognome, id_stanza, sensori) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const nuovoResidente = new Residenti.AddResidente(id, nome, cognome, id_stanza, sensori);

    const response = await axios.post(`${Auth.default.url}/residenti/nuovo`, JSON.stringify(nuovoResidente.toJson()), { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- ELIMINO UN RESIDENTE ---------------------*/
async function archiviaResidente(token, id_residente) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.delete(`${Auth.default.url}/residenti/${id_residente}`, { headers });

    return { result: true, message: response.data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { getResidenti, getResidente, getSchedaResidente, getAllarmiResidente, getSensoriResidente, patchSensoreResidente, addResidente, patchStanzaResidente, patchSchedaResidente, archiviaResidente };





