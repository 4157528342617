import React, { useState, useEffect } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import modifica from '../imgs/icons/modifica.svg';
import positionPurple from '../imgs/icons/positionPurple.svg';
import arrow from "../imgs/icons/arrowW.svg";

import sensoriNode from 'node/sensori/sensori.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_sensore;

const SchedaSensore = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [mod, setMod] = useState(0);
    const [modPosizione, setModPosizione] = useState(false);
    const [status, setStatus] = useState(0);
    const [tipo, setTipo] = useState();
    const [nome, setNome] = useState();
    const [id, setId] = useState();
    const [posizione, setPosizione] = useState();

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    async function salvaModifiche() {
        props.loading(true);
        await sensoriNode.patchNomeSensore(accountData.token, props.sensore.id, nome).then((res) => {
            if(!res.result) throw res.error;
            setMod(0);
            props.getSens();
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_sensore', props.sensore)).finally(() => {
            props.loading(false);
        });
    }

    async function mettiInQuarantena() {
            props.loading(true);
            await sensoriNode.patchSensoreMagazzino(accountData.token, props.sensore.id, true).then((res) => {
                if(!res.result) throw res.error;
                props.getSens();
            }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_sensore', props.sensore)).finally(() => {
                props.loading(false);
                props.closePopup();
            });
    }

    useEffect(() => {
        setTipo(props.sensore.tipo);
        setNome(props.sensore.nome);
        setId(props.sensore.id);
        setPosizione(props.sensore.percorso);
    }, [props.sensore]);

    return (
        <>

            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto"}}>
                <div className={selSelettore}>
                    <p>{langSet.dettagli}</p>
                </div>
                {/* <div className={status === 1 ? selSelettore : defSelettore}>
                    <p>Storico</p>
                </div> */}
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={positionPurple} alt="position" />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.tipoDiSensore}</label>
                                <p>{tipo}</p>
                            </td>
                            <td width="17.5%">
                                <div>
                                    <label>{langSet.nome}</label>
                                    {mod === 0 ? <p>{nome}</p> :
                                        <input
                                            type="text"
                                            value={nome}
                                            placeholder={langSet.inserisciNome}
                                            onChange={(e) => setNome(e.target.value)}
                                        />
                                    }
                                </div>
                            </td>
                            <td width="17.5%">
                                <div >
                                    <label>ID</label>
                                    <p>{id}</p>
                                </div>
                            </td>
                            <td width="33.5%" valign="bottom" rowSpan={2}>
                                {mod === 0 ?
                                    <div
                                        className="selettore selettoreFlex selettoreViolet"
                                        onClick={() => setMod(1)}
                                        style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : ""}}
                                    >
                                        <p>{langSet.modifica}</p>
                                        <img src={modifica} alt='modifica' className="icon-popup" />
                                    </div>
                                    : <>
                                        <div
                                            className={defContinua}
                                            onClick={() => salvaModifiche()}
                                        >
                                            <p>{langSet.salva}</p>
                                            <img className="icon-popup"  src={confirmW} alt='confirm' />
                                        </div>
                                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => { setMod(0); }}>
                                            <p>{langSet.annulla}</p>
                                            <img className="icon-popup" src={close} alt="close" />
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="scheda" style={{ display: status === 1 ? "" : "none" }}>
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="30%" style={{ border: 0 }}>Timestamp </td>
                                <td width="70%" style={{ border: 0 }}>{langSet.cambiamento} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table className="schedaSotto" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td>
                                {!modPosizione ?
                                    <div>
                                        <label style={{ float: "left", margin: ".5em" }}>{langSet.posizione}</label>
                                        <p style={{ float: "left", margin: ".5em" }}>{posizione !== '' ? posizione : 'None'}</p>
                                    </div> :
                                    null
                                }
                            </td>
                            {!modPosizione ?
                                <>
                                    {//! DA IMPLEMENTARE FORSE SUCCESSIVAMENTE
                                     /* <td width="15%" valign="bottom" align="right" rowSpan={1}>
                                        <div
                                            className="selettore selettoreFlex selettoreViolet"
                                            onClick={() => setModPosizione(true)}
                                        >
                                            <p>Modifica</p>
                                            <img src={modifica} alt='modifica' style={{ width: "1em" }} />
                                        </div>
                                    </td> */}<td align="right">
                                        <div
                                            className={defContinua}
                                            onClick={() => mettiInQuarantena()}
                                            style={{display: accountData.tipo === 4 || props.SchedaInScheda !== undefined ? "none" : ""}}
                                        >
                                            <p>{langSet.mettiInQuarantena}</p>
                                            <img src={arrow} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </>
                                :
                                null
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SchedaSensore;