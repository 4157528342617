import React, { useEffect, useRef, useState } from "react";

import userB from "../imgs/icons/userB.svg";
import arrowPurple from "../imgs/icons/arrowPurple.svg";
import positionPurple from "../imgs/icons/positionPurple.svg";
import alarmR from "../imgs/icons/alarmR.svg";
import alarmY from "../imgs/icons/alarmY.svg";
import offline from "../imgs/icons/offline.svg";
import confirmW from "../imgs/icons/confirmW.svg";
import close from "../imgs/icons/close.svg";
import caduta from "../imgs/icons/caduta.svg";
import residentiB from "../imgs/icons/residentiB.svg";
import bedB from "../imgs/icons/bedB.svg";

import residentiNode from "node/residenti/residenti.js";
import sensoreNode from "node/sensori/sensori.js";
import stanzeNode from "node/stanze/stanze.js";
import notificheNode from "node/notifiche/notifiche.js";
import dashboardNode from "node/dashboard/dashboard.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].dashboard_residente;

const DashboardAmbiente = (props) => {
    
}



export default DashboardAmbiente;