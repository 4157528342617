import crypto from 'crypto-js';


const originkey = crypto.enc.Utf8.parse('TjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8');
const iv = crypto.enc.Utf8.parse('?_q//y?}t3,pSbiK');

/*--------------------- CRIPTO/DECRIPTO ---------------------*/
function encrypt(text) {
    const key = originkey;
    
    const ivvar = iv;
    
    const encryptedStringHex = crypto.AES.encrypt(text, key, {iv: ivvar, mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7, format: crypto.format.Hex}).ciphertext.toString();
    
    return encryptedStringHex;
}

function decrypt(text) {
    
    const key = originkey;
    
    const ivvar = iv;
    
    const encryptedStringHex = crypto.AES.decrypt(text, key, {iv: ivvar, mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7, format: crypto.format.Hex}).toString(crypto.enc.Utf8);
    
    return encryptedStringHex;
}


export { encrypt, decrypt };