import React, { useState } from 'react';

function AutoplayPermissionRequest() {
  const [permission, setPermission] = useState(null);

  const requestAutoplayPermission = () => {
    if ('AutoplayPolicy' in window) {
      window.AutoplayPolicy.request()
        .then(permission => {
          setPermission(permission);
        });
    }
  };

  return (
    <div>
      {permission === 'allowed' && <p>Autoplay is allowed. You can play media with sound.</p>}
      {permission === 'denied' && <p>Autoplay is denied. You cannot play media with sound.</p>}
      {permission === 'prompt' && (
        <div>
          <p>The user will be prompted to allow or deny autoplay.</p>
          <button onClick={requestAutoplayPermission}>Request Autoplay Permission</button>
        </div>
      )}
    </div>
  );
}

export default AutoplayPermissionRequest;
