import axios from 'axios';
import * as Vayyarinfo from './vayyarinfo_model.js';
import * as Auth from '../auth/auth.js';

async function getVayyarInfo(token, sensorList) {

    try {
        const headers = { 'Authorization': `Bearer ${token}` };

        const sensorListQueryParam = sensorList.join(',');

        const response = await axios.get(`${Auth.default.url}/vayyarinfo?sensorList=${sensorListQueryParam}`, { headers: headers });

        let vayyarinfo = response.data;
        console.log(vayyarinfo);
        let data = [];
        for (let vayyar of vayyarinfo) {
            data.push(new Vayyarinfo.getVayyarinfomodel(vayyar));
        }

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: e.response.data };
        } else {
            return { result: false, error: e.toString() };
        }
    }

}

export default getVayyarInfo;